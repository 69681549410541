import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../database.service';
import { LocationService } from '../location.service';

@Component({
    selector: 'intro',
    templateUrl: './intro.component.html',
    styleUrls: ['./intro.component.scss']
})
export class IntroComponent {
    constructor(public database: DatabaseService, public location: LocationService) { }

    requestLocation() {
        this.location.requestLocation()
            .then(() => {
                this.dismiss();
            });
    }

    skip() {
        this.dismiss();
    }

    private dismiss() {
        this.database.introduced = true;
    }
}
