<div class="search-result-item-wrapper" touchEvents (touchClick)="clicked($event)">
    <div class="search-result-item-summary">

        <div class="symbol" *ngIf="symbolBackgroundImageUrl" [style.background-image]="symbolBackgroundImageUrl"></div>

        <h3>
            <span>{{waste.name}}</span>
        </h3>

        <table class="summary">
            <tr class="row-summary deposit-type" *ngIf="depositType || type">
                <th class="column-header" textKey="search-waste-waste-type">Sorteras som:</th>
                <td class="column-content">
                    {{( depositType || type ).name}}
                </td>
            </tr>

            <tr class="row-summary secondary" *ngIf="secondaryWastes.length">
                <th class="column-header" textKey="search-waste-also-applies-to">Gäller även:</th>
                <td class="column-content small">
                    <span class="also" *ngFor="let subWaste of secondaryWastes">{{subWaste.name}}</span>
                </td>
            </tr>

            <tr class="row-summary place" *ngIf="place">
                <th class="column-header" textKey="search-waste-place">Lämnas på:</th>
                <td class="column-content">{{place.name}}</td>
            </tr>
        </table>

        <place-summary *ngIf="place" [place]="place" [expanded]="expanded"></place-summary>

    </div>
    <div class="search-result-links">
        <div class="search-result-link-waste">
            <div class="expander-wrapper do-collapse">
                <expander [expanded]="expanded"></expander>
            </div>
        </div>
    </div>
    <div class="search-result-item-expanded">
        <ng-container *ngIf="expanded">
            <ng-container *ngIf="waste && waste.text">
                <hr>
                <waste-info [waste]="waste"></waste-info>
            </ng-container>
            <ng-container *ngIf="place && place.hours">
                <hr>
                <place-hours [place]="place"></place-hours>
            </ng-container>
            <ng-container *ngIf="depositType && depositType.text">
                <hr>
                <deposit-type-info *ngIf="depositType" [depositType]="depositType"></deposit-type-info>
            </ng-container>
        </ng-container>

        <!-- <div class="fade"></div> -->
    </div>
</div>