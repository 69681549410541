import { Component, OnInit, OnDestroy, ViewChild, HostListener, NgZone } from '@angular/core';
import {
    Router,
    ActivatedRoute,
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart
} from '@angular/router';
import { SearchComponent } from './search/search.component';
import { DatabaseService, PlaceType } from './database.service';
import { ContextService } from './context.service';
import { LocationService } from './location.service';
import { LoadingService } from './loading.service';
import { SwUpdate, UpdateAvailableEvent, UpdateActivatedEvent } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { LanguageService } from './language.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    private updateAvailableSubscription: Subscription;
    private updateActivatedSubscription: Subscription;
    private routingSubscription: Subscription;
    private loadingSubscription: Subscription;

    @ViewChild(SearchComponent)
    public search: SearchComponent;

    public menuOpen: boolean;

    public activeUrl: any;

    // private _isLoading = false;
    public isLoading = false;

    constructor(
        private router: Router,
        // private route: ActivatedRoute,
        private database: DatabaseService,
        private updates: SwUpdate,
        private context: ContextService,
        public location: LocationService,
        private zone: NgZone,
        public loading: LoadingService,
        private language: LanguageService,
        // private api: ApiService,
    ) {
    }

    ngOnInit() {

        this.isLoading = true;
        this.loading.isLoading = true;

        this.updateAvailableSubscription = this.updates.available.subscribe(event => this.updateAvailable(event));
        this.updateActivatedSubscription = this.updates.activated.subscribe(event => this.updateActivated(event));

        if (this.context.isNative == false) {
            try {
                this.updates.checkForUpdate();
            }
            catch { }
        }

        this.database.load()
            .then(() => {
                this.loading.isLoading = false;
                this.isLoading = false;
            });

        this.database.checkUpdates();

        document.addEventListener('resume', () => {
            this.zone.run(() => {
                this.database.checkUpdates();
                this.location.requestLocationIfPermitted();
            });
        }, false);


        if (this.context.isTouch === false) {

            setTimeout(() => {
                if (typeof (this.search) !== 'undefined') {
                    this.search.focus();
                }
            }, 10);

        }
    }

    ngOnDestroy() {
        this.updateAvailableSubscription.unsubscribe();
        this.updateActivatedSubscription.unsubscribe();
        // this.routingSubscription.unsubscribe();
        // this.loadingSubscription.unsubscribe();
    }

    // public set isLoading(loading) {
    //     this._isLoading = loading;
    // }
    // public get isLoading() {
    //     return this._isLoading;
    // }
    // public cancelRead() {
    //     this.isLoading = false;
    //     // this.loading.isLoading = false;
    // }

    private updateAvailable(event: UpdateAvailableEvent) {

        console.log('Update Available. Activating...');
        this.updates.activateUpdate();
    }

    private updateActivated(event: UpdateActivatedEvent) {
        console.log('Update Activated:', event.current.hash);

        if (
            this.context.isNative == false
            &&
            event.previous
        ) {

            const previousVersion = event.previous?.appData['version'];
            const currentVersion = event.current?.appData['version'];

            if (previousVersion != currentVersion) {
                console.log('Updated to new version ' + currentVersion + ", previously " + previousVersion);
                window.location.reload();
            } else {
                console.log('Updated but version is still ' + previousVersion);
            }
        }
    }

    @HostListener('document:visibilitychange')
    visibilityChanged() {

        if (this.context.isPWA && document.visibilityState === 'visible') {
            this.reload();
        }
    }

    get classes(): string {
        const c = ['root'];
        if (this.context.isCordova) {
            c.push('cordova');
        }
        if (this.context.isIOS) {
            c.push('ios');
        }
        if (this.context.isAndroid) {
            c.push('android');
        }
        if (!(this.context.isIOS || this.context.isAndroid)) {
            c.push('desktop');
        }
        if (this.language.rtlEnabled) {
            c.push('rtl')
        }

        return c.join(' ');
    }

    reload() {
        window.location.replace('#?_ac' + Date.now());
        // window.location.reload();
    }

    public searchChanged(term) {

        if (typeof (term) !== 'string') {
            return;
        }

        this.router.navigate(['/search', term]);
    }

    public requestLocation(event) {

        if (this.location.isRequesting) {
            if (event && event.preventDefault) {
                event.preventDefault();
            }
        } else {
            this.location.requestLocation();
        }
    }

    public toggleMenu(state?: boolean) {
        if (state === undefined) {
            state = !this.menuOpen;
        }
        this.menuOpen = state;
    }

    public isActiveLink(path, isDefault = false) {
        const isActive = this.router.isActive(path, false);
        if (isActive) {
            this.activeUrl = this.router.url;
        }
        if (isDefault && (this.router.url === '' || this.router.url === '/')) {
            // set default link to active
            return true;
        }
        return this.router.isActive(path, false);
    }

    get rightCornerRouterLink() {

        if (this.router.url.includes('languages')) {
            return [''];
        }
        else {
            return ['languages'];
        }
    }

    get selectedLanguageCode() {
        return this.language.selectedLanguage;
    }

    get selectedLanguageName() {
        const selectedLanguage = (this.language.languages || []).find(l => l.code == this.language.selectedLanguage);
        if (selectedLanguage) {

            let name = selectedLanguage[this.language.selectedLanguage + ':name'] || selectedLanguage.name || '';
            name = name.charAt(0).toUpperCase() + name.substring(1);
            return name;
        } else {
            return this.language.selectedLanguage;
        }
    }

}
