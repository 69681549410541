import { Component, Input, OnInit } from '@angular/core';
import { DatabaseService, Place, PlaceType, DayHours } from '../database.service';
import { LocationService } from '../location.service';
import * as moment from 'moment';
import { OpeningHourService } from '../opening-hour.service';

@Component({
    selector: 'place-summary',
    templateUrl: './place-summary.component.html',
    styleUrls: ['./place-summary.component.scss']
})
export class PlaceSummaryComponent {

    @Input() place: Place;
    @Input() expanded: boolean;

    constructor(public location: LocationService,
        public database: DatabaseService, public openingHourService: OpeningHourService) {

    }


    formatDistance(distance: number) {
        if (distance < 1) {
            return (Math.round(distance * 10) * 100) + ' m';
        } else {
            return (Math.round(distance * 10) / 10) + ' km';
        }
    }

    get types(): PlaceType[] {
        return this.database.getPlaceTypes(this.place);
    }

    // private getDayHours(offset: number = 0): DayHours {

    //     const date = moment();
    //     date.add(offset, 'days');
    //     return this.database.getDayHoursForDate(this.place.hours, date);

    // }

    public get otherRegion() {
        if (this.place && this.database.homeRegion) {
            return this.place.region !== this.database.homeRegion.id;
        } else {
            return true;
        }
    }

    formattedHours(offset: number, label: string, today: boolean) {
        if (this.place) {
            return this.openingHourService.formattedHours(this.place, offset, label, today);
        }
    }

    formattedReason(offset: number, label: string) {
        if (this.place) {
            return this.openingHourService.formattedReason(this.place, offset);
        }
    }

    // formattedHours(offset: number, label: string, today: boolean) {

    //     const now = moment();
    //     const day = this.getDayHours(offset);

    //     var str;
    //     if (day.from && day.to) {

    //         if (today) {

    //             var soon = now.clone().add(1, 'hours');

    //             if (now.isAfter(day.to)) {
    //                 str = this.database.getText('opening-hours-closed-today', 'Stängt för idag');
    //             } else if (now.isBefore(day.from)) {
    //                 str = this.database.getText('opening-hours-open-today', 'Öppet idag');
    //             } else if (soon.isAfter(day.to)) {
    //                 str = this.database.getText('opening-hours-closing-soon', 'Stänger snart');
    //             } else {
    //                 str = this.database.getText('opening-hours-open-now', 'Öppet nu');
    //             }

    //         } else {
    //             str = this.database.getText('opening-hours-open', 'Öppet') + ' ' + label;
    //         }

    //         str += ' ' + this.formatTime(day.from) + '-' + this.formatTime(day.to);
    //     } else {
    //         str = this.database.getText('opening-hours-closed', 'Stängt') + ' ' + label;
    //     }

    //     if (day.reason) {
    //         str += ' (' + day.reason + ')';
    //     }

    //     return str;
    // }

    // public formatTime(date: moment.Moment) {
    //     if (date.get('minutes') == 0) {
    //         return date.format('H');
    //     } else {
    //         return date.format('H:mm');
    //     }
    // }

    closed(offset: number = 0) {
        if (this.place) {
            return this.openingHourService.closed(this.place, offset);
        }
    }
    // closed(offset: number = 0) {

    //     var today = this.getDayHours(offset);

    //     if (today.to && today.from) {

    //         var now = moment();

    //         return now.isBefore(today.from) || now.isAfter(today.to);
    //     } else {
    //         return true;
    //     }
    // }

    exception(offset: number = 0) {
        if (this.place) {
            return this.openingHourService.exception(this.place, offset);
        }
    }
    // exception(offset: number = 0) {
    //     return this.getDayHours(offset).exception;
    // }


}
