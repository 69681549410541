import { Component, Input, OnInit } from '@angular/core';
import { SearchResultItemComponent } from '../search-result-item/search-result-item.component';
import { DatabaseService } from '../database.service';
import { ApiService } from '../api.service';


export enum State {

    Idle = 'idle',
    Submitting = 'submitting',
    Submitted = 'submitted',
    Failed = 'failed',
}

@Component({
    selector: 'search-result-empty',
    templateUrl: './search-result-empty.component.html',
    styleUrls: [
        '../search-result-item/search-result-item.component.scss',
        './search-result-empty.component.scss',
    ]
})
export class SearchResultEmptyComponent extends SearchResultItemComponent implements OnInit {

    static previousSubmissions = [];

    @Input() term: string;
    basicInfo: string;
    moreInfo: string;

    state: State;
    State = State; // expose enum in template

    constructor(private database: DatabaseService, private api: ApiService) { super(); }

    ngOnInit() {
        this.basicInfo = this.term;
    }

    get basicInfoPlaceholder() {
        return this.database.getText('search-no-result-form-basic-placeholder');
    }

    get moreInfoPlaceholder() {
        return this.database.getText('search-no-result-form-more-placeholder');
    }

    get canSubmit() {

        if (this.basicInfo.length === 0) {
            return false;
        }

        if (this.state === State.Submitting) {
            return false;
        }

        if (SearchResultEmptyComponent.previousSubmissions.includes(this.basicInfo.toLowerCase())) {
            return false;
        }

        return true;

    }

    submit() {

        this.state = State.Submitting;

        const data = {
            basic: this.basicInfo,
            more: this.moreInfo,
        };

        this.api.execute('suggestions', 'POST', data)
            .then(response => {
                SearchResultEmptyComponent.previousSubmissions.push(this.basicInfo.toLowerCase());
                this.state = State.Submitted;
            })
            .catch(error => {
                this.state = State.Failed;
            })
            ;
    }

}
