import { Component, Input } from '@angular/core';
import { DatabaseService, DepositType } from '../database.service';

@Component({
  selector: 'deposit-type-info',
  templateUrl: './deposit-type-info.component.html',
  styleUrls: ['./deposit-type-info.component.scss']
})
export class DepositTypeInfoComponent {

  @Input() depositType: DepositType;

  constructor(private datebase: DatabaseService) { }

}
