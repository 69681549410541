import { Component, OnInit } from '@angular/core';
import { SearchResultItemComponent } from '../search-result-item/search-result-item.component';
import { DatabaseService, Place, PlaceType, DepositType, Appliance } from '../database.service';

import * as moment from 'moment';
import { LocationService } from '../location.service';
import { LanguageService } from '../language.service';

@Component({
    selector: 'search-result-place',
    templateUrl: './search-result-place.component.html',
    styleUrls: [
        '../search-result-item/search-result-item.component.scss',
        './search-result-place.component.scss'
    ]
})
export class SearchResultPlaceComponent extends SearchResultItemComponent implements OnInit {

    public selectedDepositType: DepositType;
    public selectedAppliance: Appliance;

    constructor(public location: LocationService, public database: DatabaseService, public language: LanguageService) {
        super();
    }

    public ngOnInit() {
        if (this.depositTypes.length == 1) {
            this.selectedDepositType = this.depositTypes[0];
        }
        if (this.appliances.length == 1) {
            this.selectedAppliance = this.appliances[0];
        }
    }

    public get logo(): string {
        return this.database.getLogoForPlace(this.place);
    }

    public get place(): Place {
        return this.item as Place;
    }

    public get placeText(): string {
        if (this.place.text) {
            return this.place.text;
        }
    }

    public get depositTypes(): DepositType[] {
        if (this.place.depositTypes) {
            return this.place.depositTypes.map(depositType => {
                return this.database.getDepositType(depositType);
            })
                .filter(depositType => depositType);
            ;
        } else {
            return [];
        }
    }

    formatDistance(distance: number) {
        if (distance < 1) {
            return (Math.round(distance * 10) * 100) + ' m';
        } else {
            return (Math.round(distance * 10) / 10) + ' km';
        }
    }

    public get appliances(): Appliance[] {
        if (this.place.appliances) {
            return this.place.appliances;
        } else {
            return [];
        }
    }
}


