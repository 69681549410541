<div class="map-head">
    <div class="head-space">
        <div class="notch"></div>
    </div>
</div>

<div class="map-body">
    <div class="map" #map></div>
</div>

<div class="map-foot">
    <div class="foot-space">
        <div class="slit"></div>
    </div>
</div>