import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

import { geoAzimuthalEqualArea } from 'd3-geo';
import { ContextService } from './context.service';

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    private _requesting: boolean;

    public locationUpdate = new Subject<Location>();

    private readonly LATEST_LOCATION_STORAGE_KEY = 'latest-location';
    private readonly PERMISSION_GRANTED_STORAGE_KEY = 'location-granted';
    private storage: any;

    constructor(private context: ContextService, private zone: NgZone) {

        this.storage = localStorage || {};

        this.requestLocationIfPermitted();

        /*
        var projection = geoAzimuthalEqualArea().rotate( [-17.641,-62.194,0] );
        console.log( projection( [12.811069, 55.378683] ) );
        */

    }

    requestLocationIfPermitted() {
        if (!this.requiresUserPermission) {
            // console.log('"silently" requesting new location...');
            this.requestLocation();
        }
    }

    public get supported() {
        return (
            (
                ('geolocation' in navigator)
                &&
                (
                    location.protocol.indexOf('https') === 0
                    ||
                    location.hostname === 'localhost'
                    ||
                    this.context.isCordova
                )
            )
        );
    }

    public get requiresUserPermission() {
        const str = this.storage[this.PERMISSION_GRANTED_STORAGE_KEY];
        const v = str && JSON.parse(str);
        return !v;
    }

    public get isRequesting() {
        return this._requesting;
    }

    public get latestLocation(): Location {
        return JSON.parse(this.storage[this.LATEST_LOCATION_STORAGE_KEY] || 'null');
    }

    public requestLocation(): Promise<Location> {

        // console.log('requestLocation');

        return new Promise((resolve, reject) => {

            if (!this.supported) {
                // console.log('not supported');
                return reject();
            }

            this._requesting = true;

            // console.log('called getCurrentPosition');

            navigator.geolocation.getCurrentPosition(

                position => {

                    this.zone.run(() => {

                        // console.log('getCurrentPosition got', position);

                        const location = new Location();

                        location.time = new Date();

                        location.pos = [
                            position.coords.latitude,
                            position.coords.longitude,
                        ];

                        this.storage[this.LATEST_LOCATION_STORAGE_KEY] = JSON.stringify(location);

                        this.storage[this.PERMISSION_GRANTED_STORAGE_KEY] = JSON.stringify(true);

                        this._requesting = false;
                        resolve(location);

                        this.locationUpdate.next(location);
                    });
                },
                error => {

                    this.zone.run(() => {
                        // console.log('getCurrentPosition error', error);

                        this._requesting = false;
                        reject();
                    });
                },
                {
                    // enableHighAccuracy: true,
                    maximumAge: 1000,
                    timeout: 1000 * 5,
                }
            );
        });
    }

}

export class Location {

    pos: number[];
    time: Date;
}
