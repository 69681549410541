/// <reference types="@types/googlemaps" />
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { DatabaseService, Place } from '../database.service';
import { LocationService } from '../location.service';

import { MarkerClusterer } from './markerclusterer.js';
import { RoutingService } from '../routing.service';
import { LoadingService } from '../loading.service';

@Component({
    selector: 'map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('map') mapElement: ElementRef;
    map: google.maps.Map;
    clusterer: MarkerClusterer;
    markers: google.maps.Marker[];
    infoWindow: google.maps.InfoWindow;
    userMarker: google.maps.Marker;

    locationSubscription: Subscription;

    readonly ZOOM_APPROXIMATE = 10;
    readonly ZOOM_KNOWN = 15;

    baseURL = 'assets/maps/';

    constructor(
        private database: DatabaseService,
        private location: LocationService,
        private routing: RoutingService,
        private loading: LoadingService) { }

    ngOnInit() {

        this.loading.isLoading = true;
        this.locationSubscription = this.location.locationUpdate.subscribe(location => {
            if (this.map) {
                this.loading.isLoading = true;
                this.map.setCenter(this.pos2ll(location.pos));
                this.map.setZoom(this.ZOOM_KNOWN);
                this.updateUserMarker();
                this.loading.isLoading = false;
            }
        });
    }

    ngAfterViewInit() {
        this.checkGoogleMapsAPILoaded()
            // .then (() => {
            //     console.log('checkGoogleMapsAPILoaded loading', true);
            //     this.loading.isLoading = true;
            // })
            .then(() => this.database.load())
            .then(() => this.initMap())
            .then(() => {
                this.loading.isLoading = false;
            })
            ;
    }

    ngOnDestroy() {
        this.locationSubscription.unsubscribe();
    }

    pos2ll(pos: number[]) {
        return {
            lat: pos[0],
            lng: pos[1],
        };
    }

    initMap() {

        var pos;
        var zoom;

        if (this.location.latestLocation) {
            pos = this.location.latestLocation.pos;
            zoom = this.ZOOM_KNOWN;
        } else {
            pos = this.database.getHomePos();
            zoom = this.ZOOM_APPROXIMATE;
        }

        this.map = new google.maps.Map(this.mapElement.nativeElement, {
            zoom: zoom,
            center: this.pos2ll(pos),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            styles: [
                {
                    featureType: 'poi',
                    elementType: 'labels',
                    stylers: [
                        { visibility: 'off' }
                    ]
                }
            ]
        });


        var mapComponent = this;

        var markerClickHandler = function (a) {
            mapComponent.markerClicked(this);
        };

        this.map.addListener('click', () => this.mapClicked());

        this.markers = this.database.getPlaces().map(place => {

            const placeType = this.database.getPlaceTypes(place)[0];

            const marker = new google.maps.Marker({
                position: this.pos2ll(place.pos),
                title: place.name,
                // icon: this.baseURL + 'marker-' + placeType.id + '.png'
                icon: this.baseURL + 'marker.png'
            });

            marker['placeId'] = place.id; // custom marker data here

            marker.addListener('click', markerClickHandler);

            return marker;

        });

        this.clusterer = new MarkerClusterer(this.map, this.markers, {
            // imagePath: this.baseURL + 'm',
            imagePath: this.baseURL + 'zone'
        });

        this.updateUserMarker();
    }

    mapClicked() {

        if (this.infoWindow) {
            this.infoWindow.close();
            delete this.infoWindow;
        }
    }

    markerClicked(marker: google.maps.Marker) {

        if (this.infoWindow) {
            this.infoWindow.close();
            delete this.infoWindow;
        }

        this.map.panTo(marker.getPosition());

        var place = this.database.getPlace(marker['placeId']);

        var content = '';

        window['mapComponent'] = this;

        content += '<div class="marker-info">';
        content += '<h3>' + place.name + '</h3>';

        this.database.getPlaceTypes(place).forEach(type => {
            content += '<p>' + type.name + '</p>';
        });

        content += '<p><a href=\'' + this.database.getPlaceRouteMapURL(place) + '\' target="_blank" class="underline">'
            + this.database.getText('map-directions', 'Vägbeskrivning')
            + '</a></p>';
        content += '<p><a onclick=\'window.mapComponent.infoWindowLinkClicked(' + JSON.stringify('info') + ')\' class="underline">'
            + this.database.getText('map-more-info', 'Mer information')
            + '</a></p>';
        content += '</div>';

        this.infoWindow = new google.maps.InfoWindow({
            content,
        });

        this.infoWindow['placeId'] = place.id;

        this.infoWindow.open(this.map, marker);

    }

    infoWindowLinkClicked(type) {

        var place = this.database.getPlace(this.infoWindow['placeId']);

        switch (type) {

            case 'info':
                this.routing.navigate(['/search', place.id + ': ' + place.name]);
                break;
        }
    }

    updateUserMarker() {

        if (this.location.latestLocation) {

            var ll = this.pos2ll(this.location.latestLocation.pos);

            if (this.userMarker) {
                this.userMarker.setPosition(ll);
            }
            else {

                this.userMarker = new google.maps.Marker({
                    map: this.map,
                    position: ll,
                    title: this.database.getText('map-your-position', 'Din position'),
                    icon: {
                        url: this.baseURL + 'marker-user.png',
                        anchor: new google.maps.Point(16, 16),
                    },
                    optimized: false,
                });

            }
        }

    }

    checkGoogleMapsAPILoaded() {
        return new Promise<void>((resolve, reject) => {

            let checkCount = 0;

            const check = () => {

                if ('googleMapsAPILoaded' in window) {
                    resolve();
                } else {

                    if (checkCount++ < 50) {
                        setTimeout(check, 100);
                    } else {
                        reject();
                    }
                }

            };

            check();

        });
    }


}
