<h2>{{heading}}</h2>

<p class="info">{{text}}</p>

<div class="languages">

    <div class="language row-summary" *ngFor="let language of languageService.languages" touchEvents
        (touchClick)="selectLanguage(language)" [class.selected]="isSelectedLanguage(language)">

        <!-- <i class="fa" [class.fa-check-square-o]="isSelectedLanguage(language)"
            [class.fa-square-o]="!isSelectedLanguage(language)"></i> -->

        <i [class]="languageService.rtlEnabled ? 'fa fa-chevron-left rtl' : 'fa fa-chevron-right'"></i>

        <span class="name">{{languageText(language)}}</span>


    </div>
</div>