import { Component, OnInit, Input, HostBinding, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../loading.service';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

    // @HostBinding('class.active')
    // @Input() isLoading: boolean;
    // @Output() cancel = new EventEmitter();

    private loadingSubscription: Subscription;
    public isLoading = false;

    constructor(public loading: LoadingService) {
        this.isLoading = this.loading.isLoading;
    }

    ngOnInit() {
        this.loadingSubscription = this.loading.loadingUpdate.subscribe( (loading) => {
            console.log('loading.component.ts in loadingSubscription loading', loading);
            // this.loading.isLoading = loading;
            this.isLoading = loading;
        });
    }

    ngOnDestroy() {
        console.log('in destroy subscription()');
        this.loadingSubscription.unsubscribe();
    }

    public cancelRead() {
        this.isLoading = false;
    }

    get showLoading(): string {
        // console.log('in showLoading()', this.isLoading === true ? 'show-loading' : 'hide-loading');
        return this.isLoading === true ? 'show-loading' : 'hide-loading';
    }
}
