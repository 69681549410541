import { Injectable } from '@angular/core';

import { Router, NavigationEnd, Params, ActivatedRouteSnapshot } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  public params: BehaviorSubject<Params>;
  public paramsSnapshot: Params;

  constructor(private router: Router) {

    this.paramsSnapshot = {};
    this.params = new BehaviorSubject(this.paramsSnapshot);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(event => {

        var snapshot = this.router.routerState.snapshot.root;
        var nextParams = this.collectParams(snapshot);

        this.params.next(this.paramsSnapshot = nextParams);

      });

  }

  private collectParams(root: ActivatedRouteSnapshot): Params {

    var params: Params = {};

    (function mergeParamsFromSnapshot(snapshot: ActivatedRouteSnapshot) {

      Object.assign(params, snapshot.params);

      snapshot.children.forEach(mergeParamsFromSnapshot);

    })(root);

    return (params);

  }

  public navigate(...args) {
    this.router.navigate.apply(this.router, args);
  }

}

// https://www.bennadel.com/blog/3468-collecting-route-params-across-all-router-segments-in-angular-6-0-7.htm