import { Component, Input } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
    selector: 'expander',
    templateUrl: './expander.component.html',
    styleUrls: ['./expander.component.scss']
})
export class ExpanderComponent {

    @Input() expanded: boolean;

    constructor(public language: LanguageService) { }

}
