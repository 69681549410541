<div class="near-places-wrapper">

    <div class="illustration" *ngIf="!location.latestLocation">
        <img src="assets/images/near_places.png" />
    </div>
    <h2 textKey="near-places-heading">Nära dig</h2>
    <ng-container *ngIf="location.supported">

        <ng-container *ngIf="!location.latestLocation">
            <p class="first-paragraph-text" textKey="near-places-text">
                Här får du snabbt tips om avfallshantering nära dig. <br />
                Vi sparar inte din platsinformation.
            </p>
            <div class="buttons">
                <button touchEvents (touchClick)="requestLocation()" textKey="near-places-button" class="btn-round"
                    [disabled]="location.isRequesting">
                    Hitta min plats
                </button>
            </div>
            <!-- 
            <div class="logo-karlstad-energi-near-places">
                <img src="assets/logos/karlstad/Karlstads-energi-logo.svg" />
            </div>
            -->
        </ng-container>
        <!-- <p *ngIf="location.isRequesting"><span class="fa fa-spin fa-refresh"></span></p> -->

        <table class="near-places" *ngIf="location.latestLocation">
            <ng-container *ngFor="let category of categories">
                <ng-container *ngIf="category.places && category.places.length > 0">
                    <tr class="near-places-category">
                        <td colspan="100%">
                            <h3 [textKey]="'near-places-category-' + category.placeTypeCode"></h3>
                        </td>
                    </tr>
                    <ng-container *ngFor="let place of category.places">
                        <tr class="near-place">
                            <td class="name">{{place.name}}</td>
                            <td class="info">
                                <span class="part hours today" [class.closed]="openingHourService.closed(place, 0)"
                                    [class.exception]="openingHourService.exception(place, 0)">
                                    {{openingHourService.formattedHours(place, 0,'today',true)}}
                                </span>
                                <ng-container *ngIf="openingHourService.formattedReason(place,0)">
                                    <span>&nbsp;</span>
                                    <span class="part reason today" [class.closed]="openingHourService.closed(place, 0)"
                                        [class.exception]="openingHourService.exception(place, 0)">
                                        ({{openingHourService.formattedReason(place, 0)}})
                                        </span>
                                </ng-container>
                            </td>
                            <td class="more"><a class="underline" touchEvents (touchClick)="moreInfoLinkClicked(place)"
                                    textKey="near-places-more-info">Mer info</a></td>
                        </tr>
                        <ng-container *ngIf="openingHourService.closed(place, 0) == false">
                            <tr class="near-place-info" *ngFor="let appliance of placeAppliances(place)">
                                <td class="name">{{appliance.name}}</td>
                                <td [class]="applianceStatusClass(appliance)">{{applianceStatusText(appliance)}}</td>
                                <td></td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <tr class="space">
                        <td colspan="100%"></td>
                    </tr>
                </ng-container>
            </ng-container>
            <!-- 
            <search-result-place *ngFor="let place of nearPlaces" [item]="place"></search-result-place>
            -->
        </table>

    </ng-container>

    <ng-container *ngIf="!location.supported">
        <p textKey="near-places-geolocation-not-supported">Din enhet verkar inte stödja platstjänster.</p>
    </ng-container>
</div>