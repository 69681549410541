<div class="box" touchEvents (touchClick)="boxClick()">
    <span class="search-icon">
        <span class="fa fa-search"></span>
    </span>
    <input #input type="text" [placeholder]="placeholder" [disabled]="disabled" (blur)="blurred()" (focus)="focused()"
        touchEvents (touchClick)="focus()" />

    <!-- 
    (keydown)="keyDown($event)"
    (keyup)="keyUp($event)"
    (change)="changed()"
    -->

    <button *ngIf="searchTerm" class="clear" touchEvents (touchClick)="clear()" [class.active]="searchTerm">
        <span class="fa fa-times"></span>
    </button>

</div>