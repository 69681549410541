import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Language, LanguageService } from '../language.service';

@Component({
  selector: 'app-language-first-time',
  templateUrl: './language-first-time.component.html',
  styleUrls: ['./language-first-time.component.scss']
})
export class LanguageFirstTimeComponent implements OnInit {

  constructor(private router: Router, public languageService: LanguageService) { }

  ngOnInit(): void {
  }

  goToLanguagesSelect() {
    this.router.navigate(['/languages']);
  }

  dismissFirstTimeLanguageSelect() {
    this.languageService.firstTimeSelectedLanguage = true;
  }

  get languagesRouterLink() {

    if (this.router.url.includes('languages')) {
      return [''];
    }
    else {
      return ['languages'];
    }
  }
}
