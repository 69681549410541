<ng-container>
	<h4 textKey="place-opening-hours-heading">Öppettider</h4>
	<table>
		<tr *ngFor="let hours of comingDayHours" [class.exception]="hours.exception" [class.imminent]="hours.imminent"
			[class.closed]="!(hours.from && hours.to)">
			<td>{{formatDay( hours.date )}}</td>
			<td>{{formatDate( hours.date )}}</td>
			<ng-container *ngIf="isOpen(hours)">
				<td class="from">{{formatTime( hours.from )}}</td>
				<td class="dash">-</td>
				<td class="to">{{formatTime( hours.to )}}</td>
			</ng-container>
			<ng-container *ngIf="!isOpen(hours)">
				<td colspan="3" class="closed">{{closedText}}</td>
			</ng-container>
			<td class="reason">{{hours.reason}}</td>
		</tr>
	</table>
</ng-container>