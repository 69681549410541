<div [ngClass]="classes">

    <div class="head">

        <div class="notch"></div>

        <a class="corner left home" touchEvents [touchRouterLink]="['search']">
            <i class="fa fa-recycle"></i>
        </a>

        <a class="corner right menu" touchEvents [touchRouterLink]="rightCornerRouterLink">
            <i class="fa fa-globe"></i>
            <span class="selected-language-name">{{selectedLanguageName}}</span>
            <div class="selected-language-symbol" [ngClass]="selectedLanguageCode"></div>
        </a>

        <div class="title">
            <h1 textKey="app-title">Kasta rätt</h1>
        </div>

        <search *ngIf="isActiveLink('search', true)" class="search" (change)="searchChanged($event)"></search>

    </div>

    <div class="body">

        <div class="head-space">
            <div class="notch"></div>
        </div>
        <!-- <loading [isLoading]="isLoading" (cancel)="cancelRead()"></loading> -->
        <!--<app-loading></app-loading>-->
        <router-outlet></router-outlet>

        <div class="foot-space">
            <div class="slit"></div>
        </div>

    </div>

    <!--<div class="foot">
            <div class="footer-navigation">
            -->
    <div class="nav-position">
        <a touchEvents (touchClick)="requestLocation($event)">
            <div class="nav-position-icon">
                <!-- <span class="icon">
                            <span *ngIf="!location.isRequesting" class="fa fa-map-marker"></span>
                            <span *ngIf="location.isRequesting" class="fa fa-spin fa-refresh"></span>
                        </span> -->
                <img *ngIf="!location.isRequesting" src="assets/icons/icon-pin-gray.svg" class="default-img" />
                <img *ngIf="location.isRequesting" src="assets/icons/icon-refresh-green.svg" class="refresh-img" />
            </div>
            <div class="nav-position-text">
                <span *ngIf="!location.isRequesting" textKey="menu-update-position">Uppdatera min
                    position</span>
                <span *ngIf="location.isRequesting" textKey="menu-updating-position">Uppdaterar...</span>
            </div>
        </a>
        <div class="slit"></div>

    </div>
    <div class="nav-items">
        <div class="nav-item" [class.active]="isActiveLink('search', true)">
            <a touchEvents [touchRouterLink]="['search']">
                <div class="nav-item-icon nav-item-icon-search">&nbsp;</div>
                <div class="nav-item-icon nav-item-icon-search active">&nbsp;</div>
                <div class="nav-item-text" textKey="menu-search">Sök</div>
            </a>
        </div>
        <div class="nav-item" [class.active]="isActiveLink('map')">
            <a touchEvents [touchRouterLink]="['map']">
                <div class="nav-item-icon nav-item-icon-map">&nbsp;</div>
                <div class="nav-item-icon nav-item-icon-map active">&nbsp;</div>
                <div class="nav-item-text" textKey="menu-map">Karta</div>
            </a>
        </div>
        <div class="nav-item" [class.active]="isActiveLink('tip')">
            <a touchEvents [touchRouterLink]="['tip']">
                <div class="nav-item-icon nav-item-icon-tip">&nbsp;</div>
                <div class="nav-item-icon nav-item-icon-tip active">&nbsp;</div>
                <div class="nav-item-text" textKey="menu-tips">Tips</div>
            </a>
        </div>
        <div class="nav-item" [class.active]="isActiveLink('info')">
            <a touchEvents [touchRouterLink]="['info']">
                <div class="nav-item-icon nav-item-icon-info">&nbsp;</div>
                <div class="nav-item-icon nav-item-icon-info active">&nbsp;</div>
                <div class="nav-item-text" textKey="menu-info">Info</div>
            </a>
        </div>

        <div class="slit"></div>

    </div>

    <!--</div>-->


    <!--</div>-->


</div>