import { Component, Input, OnChanges } from '@angular/core';
import { ApiService } from '../api.service';
import { Appliance, DatabaseService, Place } from '../database.service';

@Component({
  selector: 'appliances-info',
  templateUrl: './appliances-info.component.html',
  styleUrls: ['./appliances-info.component.scss']
})
export class AppliancesInfoComponent implements OnChanges {

  constructor(private api: ApiService) { }

  @Input() appliance: Appliance;

  ngOnChanges() {
    this.getApplianceStatus(this.appliance.place)
  }

  getApplianceStatus(placeId: number) {
    // if (typeof this.appliance.status === 'undefined') {
    this.api.execute('places/' + placeId + '/status', 'GET', { id: placeId })
      .then((response: Appliance[]) => {
        this.appliance.status = response.find(a => a.id == this.appliance.id).status;
      })
    // }
  }

  get applianceStatusText() {
    return this.appliance.status ? 'Öppen' : 'Tillfälligt stängd';
  }

  get applianceStatusClass() {
    return this.appliance.status ? 'active' : 'inactive';
  }

  get applianceStatusIconClass() {
    return this.appliance.status ? 'fa fa-thumbs-up' : 'fa fa-thumbs-down';
  }
}
