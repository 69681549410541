<app-articles *ngIf="!random" [articles]="tips" [noArticlesText]="noArticlesText" basePath="/tip"></app-articles>
<ng-container *ngIf="random">
    <ng-container *ngFor="let tip of tips; let i = index">
        <h2 *ngIf="i==0" textKey="tips-heading">Ett tips...</h2>
        <div class="tips-wrapper">
            <div class="tip" touchEvents (touchClick)="goToArticle(tip.id)">
                <div class="title" *ngIf="tip.header">
                    <h3>
                        <span>{{ tip.header }}</span>
                    </h3>
                </div>
                <div class="read-more">
                    <a><span
                            [class]="language.rtlEnabled ? 'fa fa-chevron-left rtl' : 'fa fa-chevron-right'"></span><span
                            textKey="tips-read-more">Läs mer...</span></a>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>