import { Component, Input } from '@angular/core';

import { DatabaseService, Place, DayHours } from '../database.service';

import * as moment from 'moment';

@Component({
    selector: 'place-hours',
    templateUrl: './place-hours.component.html',
    styleUrls: ['./place-hours.component.scss']
})
export class PlaceHoursComponent {

    @Input() place: Place;


    constructor(private database: DatabaseService) { }

    public get comingDayHours(): DayHours[] {

        var day: moment.Moment = moment();

        var hours: DayHours[] = [];

        for (var i = 0; i < 10; i++) {

            hours.push(this.database.getDayHoursForDate(this.place.hours, day));

            day.add(1, 'days');
        }

        return hours;
    }

    public isOpen(hours: DayHours) {
        return hours.from && hours.to;
    }

    public get closedText() {
        return this.database.getText('place-opening-hours-closed', 'Stängt');
    }

    public formatDay(date: moment.Moment) {

        var formatString = 'YYYYMMDD';

        var todayString = moment().format(formatString);
        var tomorrowString = moment().add(1, 'day').format(formatString);
        var dateString = date.format(formatString);

        if (dateString == todayString) {
            return this.database.getText('opening-hours-today', 'Idag');
        } else if (dateString == tomorrowString) {
            return this.database.getText('opening-hours-tomorrow', 'Imorgon');
        } else {
            return this.database.getText('opening-hours-day-' + date.isoWeekday());

            //private static weekdayNames = ['Mån','Tis','Ons','Tor','Fre','Lör','Sön'];
            //return PlaceHoursComponent.weekdayNames[date.isoWeekday() - 1];
        }
    }

    public formatDate(date: moment.Moment) {
        return date.format('D/M');
    }

    public formatTime(date: moment.Moment) {
        return date.format('H:mm');
    }

}
