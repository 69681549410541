<ng-container *ngIf="place && place.pos">

    <ng-container *ngIf="place.hours">
        <span class="part hours today" [class.closed]="closed(0)" [class.exception]="exception(0)">
            {{formattedHours(0,'today',true)}}
        </span>
        <span *ngIf="formattedReason(0,'today')" class="part reason today" [class.closed]="closed(0)"
            [class.exception]="exception(0)">
            {{formattedReason(0,'today')}}
        </span>
        <span class="part hours" [class.exception]="exception(1)">
            {{formattedHours(1,'tomorrow',false)}}
        </span>
        <span *ngIf="formattedReason(1,'tomorrow')" class="part reason" [class.exception]="exception(1)">
            {{formattedReason(1,'tomorrow')}}
        </span>
    </ng-container>

    <span class="part city" *ngIf="place.city && otherRegion">{{place.city}}</span>

    <span class="part address">
        <a target="_blank" [href]="database.getPlaceMapURL(place)">{{place.address}}</a>
    </span>

    <ng-container>
        <span class="part type" *ngFor="let type of types">{{type.name}}</span>
    </ng-container>

</ng-container>