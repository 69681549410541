import { Component, Input, HostBinding, HostListener, Directive } from '@angular/core';
import { DatabaseService } from '../database.service';

@Directive()
export abstract class SearchResultItemComponent {

    @Input() item: any;

    @Input()
    @HostBinding('class.expanded')
    expanded: boolean;

    clicked(event: MouseEvent) {

        let element = event.target as HTMLElement;
        let noExpand = false;
        let doCollapse = false;

        do {

            const classes = element.className.split(' ');
            if (classes.includes('no-expand')) {
                noExpand = true;
            }
            if (classes.includes('do-collapse')) {
                doCollapse = true;
            }

            element = element.parentElement;
        }
        while (element);

        if (!this.expanded) {
            if (!noExpand) {
                this.toggle();
            }
        } else {
            if (doCollapse) {
                this.toggle();
            }
        }
    }

    toggle() {
        setTimeout(() => {
            this.expanded = !this.expanded;
        }, 0);
    }

}
