import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '../loading.service';
import { DatabaseService } from '../database.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {

    public infos = [];

    private databaseLoadedSubscription?: Subscription;

    public get noArticlesText() {
        return this.database.getText('info-empty');
    }

    constructor(
        private loading: LoadingService,
        private database: DatabaseService) { }

    ngOnInit() {
        this.loadInfos();

        this.databaseLoadedSubscription = this.database.loaded.subscribe(event => {
            console.log('database updated, reloading infos');
            this.loadInfos();
        });
    }

    ngOnDestroy() {
        this.databaseLoadedSubscription?.unsubscribe();
    }

    loadInfos() {
        // set this flag to true and it will be set to false in articles.components
        this.loading.isLoading = true;
        this.database.load()
            .then(() => {
                this.infos = this.database.getInfos();
            })
            // .then( () => this.loading.isLoading = false)
            ;

    }
}
