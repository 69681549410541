import { Injectable } from '@angular/core';
import { Place } from './database.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private readonly FAVORITE_PLACES_STORAGE_KEY = 'favorite-places';

  private storage: any;

  constructor() {
    this.storage = localStorage || {};
  }

  addFavoritePlaces(place: Place) {
    let places: Place[] = this.readFavoritePlaces()
    places.findIndex(p => p.id === place.id) !== -1 ? places.filter(p => p.id !== place.id) : places.push(place);
    this.storage[this.FAVORITE_PLACES_STORAGE_KEY] = JSON.stringify(places);
  }

  readFavoritePlaces() {
    return JSON.parse(this.storage[this.FAVORITE_PLACES_STORAGE_KEY]);
  }
}
