import { Component, Input } from '@angular/core';
import { DatabaseService, Place, PlaceType, DayHours } from '../database.service';

@Component({
	selector: 'place-info',
	templateUrl: './place-info.component.html',
	styleUrls: ['./place-info.component.scss']
})
export class PlaceInfoComponent {

	constructor(private database: DatabaseService) { }

	@Input() place: Place;

	get primaryType(): PlaceType {
		return this.database.getPlaceTypes(this.place)[0];
	}

	get name(): string {
		if (this.place.text) {
			return this.place.name;
		}
		else {
			return this.primaryType.name;
		}
	}

	get text(): string {
		if (this.place.text) {
			return this.place.text;
		}
		else {
			return this.primaryType.text;
		}
	}

}
