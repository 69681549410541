<ng-container *ngIf="languageService.firstTimeSelectedLanguage == false">
    <div class="languages-first-time-wrapper">
        <div class="languages-first-time-summary">
            <h3>
                <span class="title" textKey="languages-first-time-heading">Språk / Languages</span>
            </h3>
            <span textKey="languages-first-time-info">Nu finns det stöd för fler språk i appen / Now there is support
                for
                more languages in the app</span>
        </div>
        <div class="languages-first-time-buttons">
            <div class="languages-first-time-button">
                <a class="btn-round" [title]="'Gå till välj språk'" touchEvents [touchRouterLink]="languagesRouterLink">
                    <i class="fa fa-globe"></i>&nbsp;
                    <span textKey="languages-first-time-select-langauge">Välj språk / Select language</span></a>
            </div>
            <div class="languages-first-time-button">
                <a class="btn-round" [title]="'Gå till välj språk'" touchEvents
                    (touchClick)="dismissFirstTimeLanguageSelect()">
                    <i class="fa fa-check"></i>&nbsp;
                    <span textKey="languages-first-time-dismiss">Avfärda / Dismiss</span>
                </a>
            </div>
        </div>
    </div>

</ng-container>