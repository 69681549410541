import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

    public loadingUpdate = new Subject<boolean>();

    constructor() { }

    private _loading = false;
    private _prevLoadingStatus = false;

    public set isLoading(status) {
        this._loading = status;
        // only send out a subscribe if the status has been changed
        // from previous call
        if (this._prevLoadingStatus !== status) {
            //console.log('loading service send new status', status);
            this.loadingUpdate.next(status);
            this._prevLoadingStatus = status;
        }
    }

    public get isLoading () {
        return this._loading;
    }
}
