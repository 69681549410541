import { Directive, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { ContextService } from './context.service';

@Directive({
    selector: '[touchEvents]'
})
export class TouchEventsDirective {

    @Output() touchClick = new EventEmitter<MouseEvent>();
    @Input() touchRouterLink: any[];
    @Input() touchHref: string;

    private touch: Touch;
    private touchTime: number;

    constructor(private router: Router, private context: ContextService) { }

    private get touchEventsPresent() {
        return 'ontouchstart' in window;
    }

    @HostListener('click', ['$event'])
    clicked(event: MouseEvent) {
        if (!this.touchEventsPresent) {
            this.handleClick(event);
        }
    }

    @HostListener('touchstart', ['$event'])
    touchStarted(event: MouseEvent) {
        this.touch = event['changedTouches'][0];
        this.touchTime = Date.now();
    }

    @HostListener('touchend', ['$event'])
    touchEnded(event: MouseEvent) {

        const endTouch = event['changedTouches'][0];

        const touchDiffX = this.touch.screenX - endTouch.screenX;
        const touchDiffY = this.touch.screenY - endTouch.screenY;
        const touchDistance2 = touchDiffX * touchDiffX + touchDiffY * touchDiffY;

        const distanceThreshold = 15;
        const distanceThreshold2 = distanceThreshold * distanceThreshold;

        const durationThreshold = 1500;

        const duration = Date.now() - this.touchTime;

        if (touchDistance2 < distanceThreshold2 && duration <= durationThreshold) {
            this.handleClick(event);
        }
    }

    private handleClick(event: MouseEvent) {
        this.touchClick.emit(event);
        if (this.touchRouterLink) {
            this.router.navigate(this.touchRouterLink);
        } else if (this.touchHref) {

            window.open(this.touchHref, this.context.isCordova ? '_system' : '_blank');

        }
    }


}
