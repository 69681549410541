<div class="illustration">
    <img src="assets/images/near_places.png" />
</div>
<h2 textKey="intro-heading">Välkommen till Kasta rätt!</h2>
<p textKey="intro-text"></p>
<div class="buttons">
    <button touchEvents (touchClick)="requestLocation()" textKey="intro-button-allow-location" class="btn-round" [disabled]="location.isRequesting">
        Hitta min plats
    </button>
    <button touchEvents (touchClick)="skip()" textKey="intro-button-skip-location" class="btn-round" [disabled]="location.isRequesting">
        Hoppa över
    </button>
</div>
