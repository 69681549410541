import { Component, OnInit } from '@angular/core';
import { SearchResultItemComponent } from '../search-result-item/search-result-item.component';
import { DatabaseService, Waste, WasteType, SearchResultGroup, DepositType, Place, PlaceType } from '../database.service';

@Component({
    selector: 'search-result-waste',
    templateUrl: './search-result-waste.component.html',
    styleUrls: [
        './search-result-waste.component.scss',
        '../search-result-item/search-result-item.component.scss',
    ]
})
export class SearchResultWasteComponent extends SearchResultItemComponent implements OnInit {

    constructor(public database: DatabaseService) { super(); }

    public place: Place;
    public depositType: DepositType;

    ngOnInit() {

        this.database.getPlaceForWaste(this.waste).then(place => {
            this.place = place;

            return this.database.getDepositTypesForWaste(this.waste, this.place);
        })
            .then(depositTypes => {
                this.depositType = depositTypes[0];
            })
            ;

    }

    get results() {
        return (this.item as SearchResultGroup).results;
    }

    get waste() {
        return this.results[0].item as Waste;
    }

    get secondaryWastes() {
        return this.results.slice(1).map(result => result.item);
    }

    get type(): WasteType {
        return this.database.getWasteType(this.waste.type);
    }

    get placeType(): PlaceType {
        return this.database.getPlaceType(this.place.types[0]);
    }

    get symbolBackgroundImageUrl(): string {
        if (this.depositType && this.depositType.symbol) {
            const symbol = this.database.getDepositSymbol(this.depositType.symbol);
            if (symbol) {
                return 'url(assets/deposit_symbols/' + symbol.filename + '.png)';
            }
        }
        return '';
    }

    get logo(): string {
        if (this.place) {
            return this.database.getLogoForPlace(this.place);
        }
    }
}




