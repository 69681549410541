import { Injectable } from '@angular/core';
export interface Language {
	id: number;
	code: string;
	name: string;
	rtl: boolean;
}
@Injectable({
	providedIn: 'root'
})
export class LanguageService {

	private readonly SELECTED_LANGUAGE_KEY = 'selected-language';
	private readonly FIRST_TIME_SELECTED_LANGUAGE_KEY = 'first-time-selected-language';

	private storage: any;
	_languages: Language[];

	constructor() {
		this.storage = localStorage || {};
	}

	get firstTimeSelectedLanguage() {
		if (this.storage[this.FIRST_TIME_SELECTED_LANGUAGE_KEY]) {
			return JSON.parse(this.storage[this.FIRST_TIME_SELECTED_LANGUAGE_KEY])
		}
		return false;
	}

	set firstTimeSelectedLanguage(value: boolean) {
		this.storage[this.FIRST_TIME_SELECTED_LANGUAGE_KEY] = JSON.stringify(value);
	}

	get selectedLanguage() {
		return this.storage[this.SELECTED_LANGUAGE_KEY] || 'sv';
	}

	set selectedLanguage(value: string) {
		this.storage[this.SELECTED_LANGUAGE_KEY] = value;
	}

	get languages() {
		return this._languages;
	}

	set languages(value) {
		this._languages = value;
	}

	get languageCodes() {
		return this._languages.map(e => e.code);
	}

	get rtlEnabled() {
		const selectedLanguage = (this.languages || []).find(l => l.code == this.selectedLanguage);
		if (selectedLanguage) {
			let rtl = selectedLanguage['rtl'] as boolean;

			// /** Temp Hack */
			// if (selectedLanguage.code == 'ar') {
			// 	return true;
			// }

			return rtl;
		} else {
			return false;
		}
	}

	isLangField(table: string, field: string) {
		switch (table) {

			case 'wastes':
				switch (field) {
					case 'name':
					case 'text':
						return true;
					default:
						return false;
				}
			case 'waste_types':
				switch (field) {
					case 'name':
						return true;
					default:
						return false;
				}
			case 'appliance_types':
				switch (field) {
					case 'name':
						return true;
					default:
						return false;
				}
			case 'deposit_types':
				switch (field) {
					case 'name':
					case 'text':
						return true;
					default:
						return false;
				}
			case 'place_types':
				switch (field) {
					case 'name':
					case 'text':
						return true;
					default:
						return false;
				}
			case 'texts':
				switch (field) {
					default:
						return true;
				}
			case 'infos':
				switch (field) {
					case 'header':
					case 'content':
					case 'linkText':
						return true;
					default:
						return false;
				}
			case 'places':
				switch (field) {
					case 'text':
						return true;
					default:
						return false;
				}
			case 'place_hours':
				const regExp = new RegExp(/^\d+$/);
				return regExp.test(field);
			case 'place_appliances':
				switch (field) {
					case 'text':
					case 'name':
						return true;
					default:
						return false;
				}
			case 'tips':
				switch (field) {
					case 'header':
					case 'content':
					case 'linkText':
						return true;
					default:
						return false;
				}
			case 'languages':
				switch (field) {
					case 'name':
						return true;
					default:
						return false;
				}
			default:
				return false;
		}
	}


	mapLanguage(objects, table) {
		return objects.map(objects => {
			const objectSelectedLanguage = {}
			for (let field of Object.keys(objects)) {

				if (this.isLangField(table, field) == false) {
					objectSelectedLanguage[field] = objects[field];
					continue;
				}

				for (let languageCode of this.languageCodes) {
					let fieldSelectedLanguage = objects[languageCode + ':' + field];

					if (fieldSelectedLanguage === undefined && languageCode == 'sv') {
						fieldSelectedLanguage = objects[field];
					}

					if (fieldSelectedLanguage === undefined) {
						continue;
					}

					objectSelectedLanguage[languageCode + ':' + field] = fieldSelectedLanguage;
				}
				const displayedValue = objectSelectedLanguage[this.selectedLanguage + ':' + field] === undefined ?
					objectSelectedLanguage['sv:' + field] :
					objectSelectedLanguage[this.selectedLanguage + ':' + field];

				objectSelectedLanguage[field] = displayedValue;
			}
			return objectSelectedLanguage;
		});
	}
}
