import { Injectable } from '@angular/core';
import { DatabaseService, DayHours, Place } from './database.service';
import * as moment from 'moment';
import { LocationService } from './location.service';

@Injectable({
    providedIn: 'root'
})
export class OpeningHourService {


    constructor(public location: LocationService, public database: DatabaseService) { }


    private getDayHours(place, offset: number = 0): DayHours {
        const date = moment();
        date.add(offset, 'days');
        return this.database.getDayHoursForDate(place.hours, date);

    }


    formattedHours(place: Place, offset: number, label: string, today: boolean) {

        if (!place.hours) {
            return '';
        }

        const now = moment();
        const day = this.getDayHours(place, offset);

        var str;
        if (day.from && day.to) {

            if (today) {

                var soon = now.clone().add(1, 'hours');

                if (now.isAfter(day.to)) {
                    str = this.database.getText('opening-hours-closed-today', 'Stängt för idag');
                } else if (now.isBefore(day.from)) {
                    str = this.database.getText('opening-hours-open-today', 'Öppet idag');
                } else if (soon.isAfter(day.to)) {
                    str = this.database.getText('opening-hours-closing-soon', 'Stänger snart');
                } else {
                    str = this.database.getText('opening-hours-open-now', 'Öppet nu');
                }

            } else {
                str = this.database.getText('opening-hours-open-' + label, 'Öppet');
            }

            str += ' ' + this.formatTime(day.from) + '-' + this.formatTime(day.to);
        } else {
            str = this.database.getText('opening-hours-closed-' + label, 'Stängt');
        }

        // if (day.reason) {
        //     str += ' (' + day.reason + ')';
        // }

        return str;
    }

    formattedReason(place: Place, offset: number) {
        const day = this.getDayHours(place, offset);
        return day?.reason;
    }

    public formatTime(date: moment.Moment) {
        return date.format('H:mm');
    }

    closed(place: Place, offset: number = 0) {

        var today = this.getDayHours(place, offset);

        if (today.to && today.from) {

            var now = moment();

            return now.isBefore(today.from) || now.isAfter(today.to);
        } else {
            return true;
        }
    }

    exception(place: Place, offset: number = 0) {
        return this.getDayHours(place, offset).exception;
    }
}
