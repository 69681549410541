import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContextService {

    public get isTouch(): boolean {
        return 'ontouchstart' in window;
    }

    public get isPWA(): boolean {
        return !!(
            (window.navigator['standalone'])
            ||
            (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches)
        );
    }

    public get isNative(): boolean {
        return this.isCordova;
    }

    public get isCordova(): boolean {
        return environment.useCordova;
    }

    public get isIOS(): boolean {
        return !!navigator.userAgent.match(/i(Phone|Pad)/);
    }

    public get isAndroid(): boolean {
        return navigator.userAgent.includes('Linux; Android');
    }

    public get isMobile() {
        return this.isIOS || this.isAndroid || this.isCordova;
    }
}
