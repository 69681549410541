import { Directive, Input, HostBinding, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { DatabaseService } from './database.service';

@Directive({
    selector: '[textKey]'
})
export class TextDirective implements AfterViewInit {

    @Input() textKey: string;
    @Input() textParams: Object;

    private originalInnerText: string;

    constructor(private database: DatabaseService, private ref: ElementRef) { }

    ngAfterViewInit() {
        this.originalInnerText = this.ref.nativeElement.innerText;
    }

    @HostBinding('innerText')
    get text() {
        return this.database.getText(this.textKey, this.originalInnerText || '', this.textParams);
    }


}
