import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

const init = () => platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

if (environment.useCordova) {
    document.addEventListener('deviceready', () => {
        console.log('deviceready');
        init();
    }, false);
} else {
    init();
}
