import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocationService } from './location.service';
import { SwUpdate } from '@angular/service-worker';

import { environment } from '../environments/environment';

import distance from 'geo-dist';
import pLimit from 'p-limit';

import * as Fuse from 'fuse.js';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { ContextService } from './context.service';
import { Language, LanguageService } from './language.service';


export interface Waste {
    id: number;
    name: string;
    type: number;
    text?: string;
}

export interface WasteType {
    id: number;
    name: string;
}

export interface Place {
    id: number;
    name: string;
    text?: string;
    types: number[];
    depositTypes: number[];
    city: string;
    address: string;
    pos: number[];
    distance: number;
    region: number;
    hours: { string: any[] };
    appliances: Appliance[];
}

export interface Appliance {
    id?: number;
    name?: string;
    status?: number;
    text?: string;
    type?: number;
    place?: number;
}

export interface ApplianceType {
    id?: number;
    name?: string;
}

export interface PlaceType {
    id: number;
    name: string;
    text: string;
    code: string;
    logo: string;
    weight: number;
    symbol: number;
}

export interface Region {
    id: number;
    type: number;
    code: string;
    name: string;
}

export interface RegionType {
    id: number;
    name: string;
}

export interface DepositType {
    id: number;
    name: string;
    text: string;
    symbol: number;
}

export interface DepositSymbol {
    id: number;
    filename: string;
}

export interface WasteRule {
    depositType: number;
    wasteType?: number;
    region?: number;
    place?: number;
}

export interface Article {
    id?: number;
    header?: string;
    image?: number;
    content?: string;
    linkHref?: number;
    linkText?: number;
}

// export interface Language {
//     id: number;
//     code: string;
//     name: string;
// }

export enum ConditionType {
    waste = 'waste',
    wasteType = 'wasteType',
}

export interface SearchResult {
    type: SearchResultType;
    score: number;
    item: any;
}

export enum SearchResultType {
    place = 'place',
    waste = 'waste',
}

export class SearchResultGroup {
    type: SearchResultType;
    score: number;
    results: SearchResult[] = [];
}

export interface DayHours {
    date: moment.Moment;
    exception?: boolean;
    imminent?: boolean;
    from?: moment.Moment;
    to?: moment.Moment;
    reason?: string;
}


@Injectable({
    providedIn: 'root'
})
export class DatabaseService {

    constructor(
        private http: HttpClient,
        private location: LocationService,
        private updates: SwUpdate,
        private context: ContextService,
        private languageService: LanguageService
    ) {
        this.updates.activated.subscribe(e => { console.log('updates activated', e); this.reload(); });
    }

    private loadingPromise;

    private wastes: Waste[];
    private places: Place[];

    private wasteTypes: WasteType[];
    private placeTypes: PlaceType[];

    private regions: Region[];
    private regionTypes: RegionType[];

    private depositTypes: DepositType[];
    private depositSymbols: DepositSymbol[];
    private wasteRules: WasteRule[];

    private tips: Article[];
    private infos: Article[];

    private texts: Object;

    private sortPlacesPosition: number[];
    private sortPlacesPromise: Promise<any>;

    public homeRegion: Region;

    public loaded = new Subject();
    private _isLoaded = false;

    public baseURL = 'assets/database/';
    private _fileSystem: FileSystem;
    private _fileSystemPromise: Promise<FileSystem>;

    public load(): Promise<void> {

        if (!this.loadingPromise) {

            this.loadingPromise =

                this.initFilesystem()
                    .then(() => Promise.all([
                        this.loadLanguages()
                    ]))
                    .then(() =>
                        Promise.all([
                            this.loadTexts(),
                            this.loadWastes(),
                            this.loadPlaces(),
                            this.loadWasteTypes(),
                            this.loadPlaceTypes(),
                            this.loadRegions(),
                            this.loadRegionTypes(),
                            this.loadDepositTypes(),
                            this.loadDepositSymbols(),
                            this.loadWasteRules(),
                            this.loadInfos(),
                            this.loadTips(),
                        ]))
                    .then(() => {

                        const municipalityRegionType = this.getRegionType('municipality');

                        this.homeRegion = this.getRegion(undefined, municipalityRegionType, '1780');

                    })
                    .then(() => {
                        console.log('Loaded database');
                        this._isLoaded = true;
                        this.loaded.next();
                    })
                ;
        }

        return this.loadingPromise;
    }

    get isLoaded() {
        return this._isLoaded;
    }

    public reload() {
        delete this.loadingPromise;
        delete this.sortPlacesPosition;
        delete this.sortPlacesPromise;
        return this.load();
    }

    private loadWastes() {
        return (
            this.loadGroupedData('wastes')
                .then((wastes) => {
                    this.wastes = this.languageService.mapLanguage(
                        wastes,
                        'wastes'
                    ) as Waste[];
                })
        );
    }

    private loadPlaces() {
        return (
            this.loadGroupedData('places')
                .then((places) => {
                    this.places = (this.languageService.mapLanguage(places, 'places') as Place[]).map(place => {
                        for (let key of Object.keys(place)) {
                            switch (key) {
                                case 'hours':
                                    place[key] = this.languageService.mapLanguage([place[key]], 'place_hours')[0];
                                    continue;
                                case 'appliances':
                                    place[key] = this.languageService.mapLanguage(place[key], 'place_appliances');
                                default:
                                    continue;
                            }
                        }
                        return place;
                    })
                })
        );
    }

    private loadLanguages() {
        return (
            this.loadData('languages')
                .then((languages) => {
                    this.languageService.languages = languages;
                    this.languageService.languages = this.languageService.mapLanguage(
                        languages,
                        'languages'
                    ) as Language[];
                })
        );
    }

    private loadWasteTypes() {
        return (
            this.loadData('wasteTypes')
                .then((wasteTypes) => {
                    this.wasteTypes = this.languageService.mapLanguage(
                        wasteTypes,
                        'waste_types'
                    ) as WasteType[];
                })
        );
    }

    private loadPlaceTypes() {
        return (
            this.loadData('placeTypes')
                .then((placeTypes) => {
                    this.placeTypes = this.languageService.mapLanguage(
                        placeTypes,
                        'place_types'
                    ) as PlaceType[];
                })
        );
    }

    private loadRegions() {
        return (
            this.loadData('regions')
                .then((regions) => {
                    this.regions = regions as Region[];
                })
        );
    }

    private loadRegionTypes() {
        return (
            this.loadData('regionTypes')
                .then((regionTypes) => {
                    this.regionTypes = regionTypes as RegionType[];
                })
        );
    }

    private loadDepositTypes() {
        return (
            this.loadData('depositTypes')
                .then((depositTypes) => {
                    this.depositTypes = this.languageService.mapLanguage(depositTypes,
                        'deposit_types'
                    ) as DepositType[];
                })
        );
    }

    private loadDepositSymbols() {
        return (
            this.loadData('depositSymbols')
                .then((depositSymbols) => {
                    this.depositSymbols = depositSymbols as DepositSymbol[];
                })
        );
    }

    private loadWasteRules() {
        return (
            this.loadData('wasteRules')
                .then((wasteRules) => {
                    this.wasteRules = wasteRules as WasteRule[];
                })
        );
    }

    private loadTips() {
        return (
            this.loadData('tips')
                .then((tips) => {
                    this.tips = this.languageService.mapLanguage(tips,
                        'tips'
                    ) as Article[];
                })
        );
    }

    private loadInfos() {
        return (
            this.loadData('infos')
                .then((infos) => {
                    this.infos = this.languageService.mapLanguage(infos,
                        'infos'
                    ) as Article[];
                })
        );
    }

    private loadTexts() {
        return (
            this.loadData('texts')
                .then((texts) => {
                    this.texts = this.languageService.mapLanguage([texts], 'texts')[0];
                })
        );
    }

    public get textsLoaded(): boolean {
        return !!this.texts;
    }

    private missingTexts = [];
    public getText(key: string, def?: string, params?: Object) {
        let text;
        if (this.texts && key in this.texts) {
            text = this.texts[key];
        } else {
            if (this.textsLoaded) {

                if (!this.missingTexts.includes(key)) {
                    this.missingTexts.push(key);
                    console.error('Missing text key', key, 'default is "' + def + '"');
                }
            }
            if (def !== undefined) {
                text = def;
            } else {
                text = key;
            }
        }

        if (params) {
            for (const token of Object.keys(params)) {
                text = text.replace('%' + token + '%', params[token]);
            }
        }

        return text;
    }

    private get letterGroups(): string[] {
        const a = 'a'.charCodeAt(0);
        const z = 'z'.charCodeAt(0);
        const groups = [];

        for (let ascii = a; ascii <= z; ascii++) {
            groups.push(String.fromCharCode(ascii));
        }
        groups.push('other');

        return groups;
    }

    private loadData(name) {

        // console.log('Loading ' + name + '...');

        const path = this.baseURL + name + '.json';

        return this.initFilesystem()
            .then(fs => {
                if (fs) {
                    return this.readFile(path).catch(e => null);
                }
            })
            .then(data => {
                if (data) {
                    // console.log('Got it from file: ' + path);
                    return data;
                } else {
                    return this.http.get(path).toPromise();
                }
            });
    }
    ç
    private loadGroupedData(name) {

        // console.log('Loading ' + name + '...');

        const limit = pLimit(10);

        let totalData = [];

        return (

            Promise.all(this.letterGroups.map(letterGroup => {

                return limit(() => {
                    return this.loadData(name + '_' + letterGroup)
                        .then((data) => {

                            totalData = totalData.concat(data);
                        });
                });

            }))
                .then(() => {
                    return totalData;
                })
        );
    }

    private initFilesystem(): Promise<FileSystem> {

        if (!environment.useFilesystem) {
            return Promise.resolve(null);
        }

        if (this._fileSystem) {
            return Promise.resolve(this._fileSystem);
        }

        if (this._fileSystemPromise) {
            return this._fileSystemPromise;
        }

        return this._fileSystemPromise = Promise.resolve().then(() => {

            const getRFS = () => window['requestFileSystem'] || window['webkitRequestFileSystem'];

            let promise;

            if (getRFS()) {
                promise = Promise.resolve();
            } else if (this.context.isCordova) {

                if (window['isFilePluginReadyRaised']) {
                    console.log('isFilePluginReadyRaised set');
                    promise = Promise.resolve();
                } else {
                    promise = new Promise<void>(resolve => {
                        window.addEventListener('filePluginIsReady', () => {
                            console.log('filePluginIsReady fired');
                            resolve();
                        }, false);
                    });
                }
            }

            return promise.then(() => getRFS());
        })
            .then(requestFileSystem => {

                const constantHolder = window['LocalFileSystem'] || window;

                return new Promise(resolve =>
                    requestFileSystem(constantHolder.PERSISTENT, 0, fs => {
                        resolve(fs);
                    })
                );

            })
            .then((fs: FileSystem) => {

                this._fileSystem = fs;
                return fs;
            });
    }

    public checkUpdates() {

        const manifestFilename = 'ngsw.json';
        const ac = '?_ac=' + Date.now();

        interface Asset {
            url;
            hash;
            data?;
        }

        return this.initFilesystem()
            .then(fs => {

                if (!fs) {
                    return Promise.resolve();
                }

                return Promise.resolve()
                    .then(() => {

                        return Promise.all([

                            this.readFile(manifestFilename).catch(e => null),
                            this.http.get(manifestFilename).toPromise().catch(e => null),
                            this.http.get(environment.databaseUrl + manifestFilename + ac).toPromise().catch(e => null),
                        ]);

                    })
                    .then(([
                        manifestLocalFile,
                        manifestLocalOriginal,
                        manifestRemote,
                    ]) => {

                        /*
                        console.log('manifestLocalFile', manifestLocalFile);
                        console.log('manifestLocalOriginal', manifestLocalOriginal);
                        console.log('manifestRemote', manifestRemote);
                        */

                        let manifestLocal = manifestLocalFile || manifestLocalOriginal;

                        if (!manifestLocal && manifestRemote) {
                            this.writeFile(manifestFilename, manifestRemote);
                            manifestLocal = manifestRemote;
                        }

                        let assetsLocal: Asset[];
                        let assetsRemote: Asset[];

                        [assetsLocal, assetsRemote] = [manifestLocal, manifestRemote].map(manifest => {

                            if (manifest) {

                                const assets = manifest.assetGroups.find(g => g.name === 'assets');
                                const hashTable = manifest.hashTable;
                                if (assets) {
                                    const urls = assets.urls;

                                    return urls.map(url => ({
                                        url,
                                        hash: hashTable[url],
                                    }));
                                }

                            }

                            return [];
                        });

                        const assetsDiff = assetsRemote.filter(remoteAsset => {
                            const localAsset = assetsLocal.find(a => a.url === remoteAsset.url);
                            return (!localAsset) || localAsset.hash !== remoteAsset.hash;
                        });

                        return Promise.resolve()
                            .then(() => Promise.all(assetsDiff.map(asset => {

                                console.log('Loading updated asset', asset.url);

                                return this.http.get(environment.databaseUrl + asset.url + ac).toPromise()
                                    .catch(e => null)
                                    .then(data => ({
                                        data,
                                        ...asset
                                    }));
                            }
                            )))
                            .then(loadedAssets => ({
                                loadedAssets,
                                manifestRemote,
                            }));

                    })
                    .then(({ loadedAssets, manifestRemote }) => {

                        if (loadedAssets.length === 0) {

                            console.log('All assets up to date');

                            return false;

                        } else {

                            return Promise.all(loadedAssets.map(asset => this.writeFile(asset.url, asset.data)))
                                .then(() => {
                                    console.log('Updating manifest', manifestRemote);
                                    this.writeFile(manifestFilename, manifestRemote);
                                    return true;
                                });
                        }

                    })
                    .then(updated => {
                        if (updated) {
                            this.reload();
                        }
                    });
            });
    }

    private readFile(path: string): Promise<any> {
        return this.initFilesystem()
            .then(fs => new Promise((resolve, reject) => fs.root.getFile(path, {}, fe => {
                fe.file(file => {
                    const fr = new FileReader();
                    fr.onloadend = () => {
                        // console.log('readFile', path, fr.result as string);
                        try {
                            resolve(JSON.parse(fr.result as string));
                        } catch (e) {
                            reject(e);
                        }
                    };
                    fr.readAsText(file);
                }, e => reject(e));
            }, e => reject(e)))
            );
    }

    private writeFile(path: string, data: any): Promise<void> {

        return this.initFilesystem()
            .then(fs => {

                const pathParts = path.split('/');
                const createPaths: string[] = [];
                for (let n = 1; n < pathParts.length; n++) {
                    const createParts = pathParts.slice(0, n);
                    const createPath = createParts.join('/');
                    if (createPath.length > 0) {
                        createPaths.push(createPath);
                    }
                }

                return Promise.all(createPaths.map(createPath =>
                    new Promise<void>((resolve, reject) => fs.root.getDirectory(createPath, { create: true },
                        () => resolve(),
                        e => reject(e)
                    ))
                ))
                    .then(() => new Promise<void>((resolve, reject) => {
                        fs.root.getFile(path, { create: true }, fe => {
                            fe.createWriter(fw => {
                                fw.onwriteend = () => {
                                    if (fw.length === 0) { // truncate completed
                                        // console.log('truncate completed', path);
                                        fw.write(new Blob([JSON.stringify(data)], { type: 'application/json' }));
                                    } else { // write completed
                                        // console.log('write completed', path);
                                        resolve();
                                    }
                                };
                                fw.truncate(0);
                            }, e => reject(e));
                        }, e => reject(e));
                    })
                    );
            });
    }

    public getPlaceTypes(place?: Place) {
        if (!place) {
            return this.placeTypes;
        } else {

            const types = place.types.map(typeId => this.getPlaceType(typeId));

            types.sort((a, b) => {
                return b.weight - a.weight;
            });

            return types;
        }
    }

    searchWastes(term: string): Promise<SearchResult[]> {

        return this.load().then(
            () => {
                return this.searchItems(term, SearchResultType.waste, this.wastes);
            });

    }

    searchPlaces(term: string): Promise<SearchResult[]> {

        // const t0 = Date.now();

        return this.load()
            .then(() => {

                // if the terms contains a colon preceded by a number, just return the place with that ID
                if (term.includes(':')) {
                    const [strId] = term.split(':');
                    const id = Number(strId);
                    let place: Place;
                    if (id) {
                        place = this.getPlace(id);
                    }
                    if (place) {
                        const searchResult: SearchResult = {
                            type: SearchResultType.place,
                            item: place,
                            score: 0,
                        };
                        return Promise.resolve([searchResult]);
                    }
                }

                return Promise.resolve()
                    .then(() => {
                        return this.sortPlaces();
                    })
                    .then(() => {
                        return this.searchItems(term, SearchResultType.place, this.places);
                    })
                    .then(searchResults => {

                        searchResults.forEach(searchResult => {

                            const place: Place = searchResult.item as Place;

                            const m = 20;

                            const d = Math.min(1, Math.max(0, place.distance / m));

                            searchResult.score = (d * 8 + searchResult.score * 2) / 10;

                            // if( (searchResult.item as Place).region != this.homeRegion.id ){
                            // searchResult.score += .5;

                        });

                        // console.log('Total search time: ' + (Date.now() - t0));

                        return searchResults;
                    })
                    /*
                    .then( searchResults => {
        
                        searchResults.forEach( searchResult => {
                            searchResult.score *= .5;
        
                            if( (searchResult.item as Place).region != this.homeRegion.id ){
                                searchResult.score += .5;
                            }
                        });
        
                        return searchResults;
                    })
                    */
                    ;
            });

    }

    private searchItems(term: string, type: SearchResultType, items: any[]): Promise<SearchResult[]> {


        return new Promise((resolve, reject) => {

            const t0 = Date.now();

            const fuseOptions = {
                includeScore: true,
                threshold: 0.3,
                location: 0,
                distance: 100,
                sort: false,
                tokenize: true,
                // maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: [
                    'name',
                    // 'author.firstName'
                ]
            };


            let index = 0;
            // let passNr = 1;
            const results: SearchResult[] = [];

            const millisPerPass = 50;
            const itemsPerFuse = 200;

            const nextPass = () => {

                const startTime = Date.now();

                // console.log('Type: ' + type + ', Pass ' + (passNr++) + ', time: ' + (startTime - t0));

                do {

                    const fuseItems = items.slice(index, index + itemsPerFuse);

                    const fuse = new Fuse(fuseItems, fuseOptions);

                    const fuseResults = fuse.search(term);

                    if (fuseResults.length > 0) {
                        // console.log('term', term, 'searchResults', fuseResults);

                        results.push(...fuseResults as SearchResult[]); // should be faster than concat
                    }

                    index += itemsPerFuse;

                    if (index >= items.length) {

                        for (const r of results) {
                            r.type = type;
                        }

                        return resolve(results);
                    }
                }
                while (Date.now() - startTime < millisPerPass);

                setTimeout(nextPass, 0);
            };

            nextPass();

        });

    }

    public groupResults(results: SearchResult[]): SearchResultGroup[] {

        const groups = [];

        results = results.slice();

        while (results.length) {

            const result1 = results.shift();

            const group = new SearchResultGroup();
            groups.push(group);

            group.type = result1.type;
            group.score = result1.score;
            group.results = [result1];

            for (let n = results.length - 1; n >= 0; n--) {

                const result2 = results[n];

                if (this.resultsGroupable(result1, result2)) {

                    results.splice(n, 1);

                    group.results.push(result2);
                }
            }

        }

        return groups;
    }

    private resultsGroupable(a: SearchResult, b: SearchResult) {

        if (a.type !== b.type) {
            return false;
        }

        if (a.type === SearchResultType.waste) {

            const wasteA = a.item as Waste;
            const wasteB = b.item as Waste;

            return wasteA.type === wasteB.type;
        }

        return false;
    }


    public getWasteType(id: number): WasteType {

        for (const type of this.wasteTypes) {
            if (type.id === id) {
                return type;
            }
        }
    }

    public getPlaceType(id: number): PlaceType {

        for (const type of this.placeTypes) {
            if (type.id === id) {
                return type;
            }
        }

    }

    public getDepositType(id: number): DepositType {

        for (const type of this.depositTypes) {
            if (type.id === id) {
                return type;
            }
        }

    }

    public getDepositSymbol(id: number): DepositSymbol {

        for (const symbol of this.depositSymbols) {
            if (symbol.id === id) {
                return symbol;
            }
        }

    }

    public getRegionType(idOrName: any): RegionType {

        for (const type of this.regionTypes) {
            if (type.id === idOrName || type.name === idOrName) {
                return type;
            }
        }

    }

    public getPlace(id: number): Place {
        for (const place of this.places) {
            if (place.id === id) {
                return place;
            }
        }
    }

    public getPlaces(): Place[] {

        return this.places;
    }


    public getPlaceMapURL(place: Place) {
        return 'https://www.google.com/maps?q=' + encodeURIComponent(place.pos.join(','));
    }

    public getPlaceRouteMapURL(place: Place) {

        let from;
        if (this.location.latestLocation) {
            from = this.location.latestLocation.pos.join(',');
        } else {
            from = 'My Location';
        }

        const to = place.pos.join(',');

        return 'https://www.google.com/maps?saddr=' + encodeURIComponent(from) + '&daddr=' + encodeURIComponent(to);

    }


    public getHomePos(): number[] {

        return this.getRegionPosition(this.homeRegion);

    }

    public getRegionPosition(region: Region): number[] {

        const p = [0, 0];

        const places = this.places.filter(place => place.region === region.id);

        places.forEach(place => {
            p[0] += place.pos[0];
            p[1] += place.pos[1];
        });

        p[0] /= places.length;
        p[1] /= places.length;

        return p;
    }



    // tslint:disable-next-line: member-ordering
    private weekdayCodes = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
    public getDayHoursForDate(hours: { string: any[] }, date: moment.Moment): DayHours {
        const dayHours: DayHours = {
            date: date.clone(),
            imminent: this.dateIsImminent(date),
        };
        if (typeof (hours) === 'undefined') {
            return dayHours;
        }

        const weekDay = date.isoWeekday() - 1;
        const dateString = date.format('YYYYMMDD');

        let parts = [];

        Object.keys(hours).forEach(day => {

            const p = hours[day];

            if (Number(day)) {

                if (String(day) === dateString) {

                    parts = p;
                    dayHours.exception = true;
                }

            } else if (!dayHours.exception) {

                let range: any[] = day.split('-');
                if (range.length === 1) {
                    range = [day, day];
                }
                range = range.map(weekdayCode => this.weekdayCodes.indexOf(weekdayCode));

                const [from, to] = range;

                if (weekDay >= from && weekDay <= to) {
                    parts = p;
                }

            }
        });

        if (parts.length > 0) {

            if (parts.length >= 2) {
                dayHours.from = this.momentFromDateTime(date, parts[0]);
                dayHours.to = this.momentFromDateTime(date, parts[1]);
            }

            const lastPart = parts[parts.length - 1];

            if (!Number(lastPart)) {
                dayHours.reason = lastPart;
            }
        }

        return dayHours;
    }

    private dateIsImminent(date: moment.Moment) {

        const formatString = 'YYYYMMDD';

        const todayString = moment().format(formatString);
        const tomorrowString = moment().add(1, 'day').format(formatString);
        const dateString = date.format(formatString);

        return dateString === todayString || dateString === tomorrowString;
    }

    private momentFromDateTime(date: moment.Moment, time: number) {

        const timeDate = date.clone();

        const str = String(time);

        let m, h;

        if (str.length >= 3) {
            h = Number(str.substr(0, str.length - 2));
            m = Number(str.substr(str.length - 2));
        } else {
            h = Number(str);
            m = 0;
        }

        timeDate.set('hour', h);
        timeDate.set('minute', m);
        timeDate.set('second', 0);

        return timeDate;
    }

    public getRegion(id?: number, type?: RegionType | number, code?: string): Region {

        let typeId: number;
        if (typeof (type) === 'object') {
            typeId = type.id;
        } else {
            typeId = type;
        }

        for (let n = 0; n < this.regions.length; n++) {

            const region = this.regions[n];

            if (id && region.id !== id) {
                continue;
            }

            if (type && region.type !== typeId) {
                continue;
            }

            if (code && region.code !== code) {
                continue;
            }

            return region;
        }
    }

    public sortPlaces() {

        let measureFromPosition;

        if (this.location.latestLocation) {
            measureFromPosition = this.location.latestLocation.pos;
        } else if (this.homeRegion) {
            measureFromPosition = this.getRegionPosition(this.homeRegion);
        }

        if (!measureFromPosition) {

            return Promise.resolve();

        } else {

            const [latFrom, lngFrom] = measureFromPosition;

            if (
                this.sortPlacesPromise
                &&
                this.sortPlacesPosition
                &&
                latFrom === this.sortPlacesPosition[0]
                &&
                lngFrom === this.sortPlacesPosition[1]
            ) {

                return this.sortPlacesPromise;
            }

            this.sortPlacesPosition = [latFrom, lngFrom];

            return this.sortPlacesPromise = new Promise<void>(resolve => {

                let index = 0;

                // var passNr = 1;
                const millisPerPass = 10;

                const nextPass = () => {

                    // console.log( 'Pass '+passNr++);

                    const startTime = Date.now();
                    do {

                        const place = this.places[index++];

                        const [latTo, lngTo] = place.pos;

                        place.distance = distance(latFrom, lngFrom, latTo, lngTo);

                        if (index >= this.places.length) {
                            return resolve();
                        }
                    }
                    while (Date.now() - startTime < millisPerPass);

                    setTimeout(nextPass, 0);

                };
                nextPass();

            })
                .then(() => {

                    this.places.sort((a, b) => a.distance - b.distance);
                })
                ;
        }
    }

    public getNearPlaces(max: number = 1, type: PlaceType | number = -1, depositTypes?: DepositType[] | number[]): Promise<Place[]> {

        let typeId;

        if (typeof (type) === 'object') {
            typeId = type.id;
        } else {
            typeId = type;
        }

        let depositTypeIds: number[];

        if (Array.isArray(depositTypes)) {
            depositTypeIds = (depositTypes as any[]).map(dt => typeof (dt) === 'object' ? dt.id : dt);
        } else {
            depositTypeIds = [];
        }


        return (
            this.sortPlaces()
                .then(() => {

                    const nearPlaces: Place[] = [];

                    for (let i = 0; i < this.places.length; i++) {

                        const place = this.places[i];

                        if (typeId !== -1 && place.types.indexOf(typeId) === -1) {
                            continue;
                        }

                        if (depositTypeIds.length > 0) {
                            let found = false;

                            for (const id of depositTypeIds) {
                                if (place.depositTypes.includes(id)) {
                                    found = true;
                                    break;
                                }
                            }

                            if (!found) {
                                continue;
                            }
                        }

                        nearPlaces.push(place);

                        if (nearPlaces.length >= max) {
                            break;
                        }
                    }

                    return nearPlaces;
                })
        );
    }

    private getWasteRuleWeight(rule: WasteRule) {
        return 1 + (rule.region ? 0 : 1) + (rule.place ? 0 : 2);
    }

    public getDepositTypesForWaste(waste: Waste, place?: Place | number, region?: Region | number): DepositType[] {

        let placeId;
        let regionId;

        if (place && typeof (place) === 'object') {
            placeId = place.id;
        } else {
            placeId = place;
        }

        if (region && typeof (region) === 'object') {
            regionId = region.id;
        } else if (region) {
            regionId = region;
        } else if (place && typeof (place) === 'object') {
            regionId = place.region;
        } else if (this.homeRegion) {
            regionId = this.homeRegion.id;
        } else {
            regionId = undefined;
        }

        const rules = this.wasteRules.filter(rule => {

            if (rule.wasteType && rule.wasteType !== waste.type) {
                return false;
            }

            if (placeId && rule.place && rule.place !== placeId) {
                return false;
            }

            if (regionId && rule.region && rule.region !== regionId) {
                return false;
            }

            return true;
        });

        rules.sort((a, b) => this.getWasteRuleWeight(a) - this.getWasteRuleWeight(b));

        return rules.map(rule => this.getDepositType(rule.depositType));
    }

    public getPlaceForWaste(waste: Waste): Promise<Place> {

        const depositTypes = this.getDepositTypesForWaste(waste);

        return (
            this.getNearPlaces(1, undefined, depositTypes)
                .then(nearPlaces => nearPlaces.length ? nearPlaces[0] : null)
        );

    }

    public getLogoForPlace(place: Place | number) {

        if (typeof (place) === 'number') {
            place = this.getPlace(place);
        }

        if (place.types.length === 1) {
            const placeType = this.getPlaceType(place.types[0]);

            if (placeType.logo) {
                return placeType.logo;
            }
        }

        const region = this.getRegion(place.region);
        if (region.code === '1780') {
            return 'karlstad';
        }

    }

    public getInfos(): Article[] {
        return this.infos;
    }

    public getTips(): Article[] {
        return this.tips;
    }

    public get introduced(): boolean {
        return !!localStorage.introduced;
    }

    public set introduced(v: boolean) {
        localStorage.introduced = v;
    }
}




