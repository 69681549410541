<h3>
    <span textKey="search-no-result-heading" [textParams]="{search:term}">Inga resultat för "%search%"</span>
</h3>
<p textKey="search-no-result-text">Din sökning gav tyvärr ingen träff.</p>
<hr>
<div class="field row align-items-center">
    <div class="col-12 field-label" textKey="search-no-result-form-basic-heading">Jag saknar</div>
    <div class="col-12">
        <input class="form-control" [(ngModel)]="basicInfo" [placeholder]="basicInfoPlaceholder">
    </div>
</div>
<div class="field row align-items-center">
    <div class="col-12 field-label" textKey="search-no-result-form-more-heading">Mer specifikt</div>
    <div class="col-12">
        <textarea class="form-control" [(ngModel)]="moreInfo" [placeholder]="moreInfoPlaceholder"></textarea>
    </div>
</div>
<div class="row">
    <div class="col-12 text-center">
        <button textKey="search-no-result-form-submit-button" touchEvents (touchClick)="submit()" class="btn-round"
            [disabled]="!canSubmit">Skicka</button>
    </div>
    <div class="col-12 text-center">
        <p *ngIf="state==State.Submitting" textKey="search-no-result-form-submitting"></p>
        <p *ngIf="state==State.Submitted" textKey="search-no-result-form-submitted"></p>
        <p *ngIf="state==State.Failed" textKey="search-no-result-form-failed" class="failed"></p>
    </div>
</div>