import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Language, LanguageService } from '../language.service';
import { RoutingService } from '../routing.service';

@Component({
    selector: 'app-languages',
    templateUrl: './languages.component.html',
    styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

    public heading: string = '';
    public text: string = '';

    constructor(
        public languageService: LanguageService,
        private database: DatabaseService,
        private routing: RoutingService,
    ) { }

    ngOnInit(): void {
        this.database.load().then(() => {
            this.heading = this.database.getText('languages-heading');
            this.text = this.database.getText('languages-text');
        });
        this.languageService.firstTimeSelectedLanguage = true;
    }

    upperCase(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    languageText(language: Language) {

        let nativeName = language[language.code + ':name'];
        let swedishName = language['sv:name'];

        let name;

        if (swedishName == nativeName || !nativeName) {
            name = this.upperCase(swedishName);
        } else {
            name = this.upperCase(nativeName) + ' (' + this.upperCase(swedishName) + ')';
        }

        return name;
    }

    selectLanguage(language: Language) {
        this.languageService.selectedLanguage = language.code;
        this.database.reload().then(() => {
            this.routing.navigate([''])
        });
        this.languageService.firstTimeSelectedLanguage = true;
    }

    isSelectedLanguage(language: Language) {
        return this.languageService.selectedLanguage == language.code;
    }
}
