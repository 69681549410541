<div class="search-result-item-wrapper" touchEvents (touchClick)="clicked($event)">
    <div class="search-result-item-summary">
        <h3>
            <span class="title">{{place.name}}</span>

            <ng-container class="part distance" *ngIf="place.distance && location.latestLocation">
                <span class="distance">
                    ({{formatDistance( place.distance )}})
                </span>
            </ng-container>
        </h3>

        <place-summary [place]="place" [expanded]="expanded"></place-summary>

    </div>
    <div class="search-result-links">
        <div class="search-result-link">
            <div class="part directions no-expand" *ngIf="place && place.pos">
                <a target="_blank" touchEvents [touchHref]="database.getPlaceRouteMapURL(place)"
                    class="directions-link">
                    <img src="assets/icons/icon-direction-gray.svg" class="directions-icon"
                        [class]="language.rtlEnabled ? 'rtl' : ''" />
                    <span textKey="search-place-directions">Vägbeskrivning</span>
                </a>
            </div>
        </div>
        <div class="search-result-link-space">&nbsp;</div>
        <div class="search-result-link">
            <div class="expander-wrapper do-collapse">
                <expander [expanded]="expanded"></expander>
            </div>
        </div>
    </div>
    <div class="search-result-item-expanded">

        <ng-container *ngIf="expanded">
            <hr />
            <place-hours *ngIf="place.hours" [place]="place"></place-hours>

            <hr>

            <ng-container *ngIf="place.appliances && place.appliances.length > 1">
                <table class="summary">
                    <tr class="appliances row-summary">
                        <th class="column-header" textKey="search-place-appliances">Här finns:</th>
                        <td class="column-content">
                            <span class="pill" *ngFor="let appliance of appliances" touchEvents
                                (touchClick)="selectedAppliance = appliance"
                                [class.selected]="appliance==selectedAppliance">
                                {{appliance.name}}</span>
                        </td>
                    </tr>
                </table>

                <div class="select-appliance" *ngIf="!selectedAppliance">
                    <span textKey="search-place-click-appliances-list">Klicka i listan för mer info!</span>
                    &nbsp;
                    <span class="fa fa-hand-o-up"></span>
                </div>

                <appliances-info *ngIf="selectedAppliance" [appliance]="selectedAppliance"></appliances-info>

                <hr>
            </ng-container>
            <ng-container *ngIf="place.appliances && place.appliances.length == 1">

                <appliances-info *ngIf="selectedAppliance" [appliance]="selectedAppliance"></appliances-info>

                <hr>
            </ng-container>

            <table class="summary">
                <tr class="deposit-types row-summary">
                    <th class="column-header" textKey="search-place-waste-types">Här sorteras:</th>
                    <td class="column-content">
                        <span class="pill" *ngFor="let depositType of depositTypes" touchEvents
                            (touchClick)="selectedDepositType = depositType"
                            [class.selected]="depositType==selectedDepositType">
                            {{depositType.name}}</span>
                    </td>
                </tr>
            </table>

            <div class="select-deposit-type" *ngIf="!selectedDepositType">
                <span textKey="search-place-click-waste-type-list">Klicka i listan för mer info!</span>
                &nbsp;
                <span class="fa fa-hand-o-up"></span>
            </div>

            <deposit-type-info *ngIf="selectedDepositType" [depositType]="selectedDepositType"></deposit-type-info>

            <hr>



            <place-info [place]="place"></place-info>

        </ng-container>

    </div>
</div>