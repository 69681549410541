import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient) { }

    execute(path: string, method: string = 'GET', body?: Object | FormData): Promise<any> {

        let url = environment.apiBaseUrl + path;

        const options = {
            withCredentials: true
        };

        let promise;

        method = method.toUpperCase();

        if (method === 'GET') {

            if (url.includes('?')) {
                url += '&';
            } else {
                url += '?';
            }

            url += '_ac=' + Date.now();

            promise = this.http.get(url, options).toPromise();
        } else if (method === 'POST') {
            promise = this.http.post(url, body, options).toPromise();
        }

        if (!promise) {
            return Promise.reject();
        }

        return promise.then(response => {
            return response;
        });

    }
}
