import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { LoadingService } from '../loading.service';
import { RoutingService } from '../routing.service';
import { Subscription } from 'rxjs';
import { LanguageService } from '../language.service';

export interface IArticle {
    id: number;
    header: string;
    content: string;
    image: string;
    linkHref: string;
    linkText: string;
    expanded: boolean;
}

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
    styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit, OnDestroy, AfterViewChecked {

    private defaultNoArticleText = 'Inga artiklar finns';

    @Input() articles: IArticle[];
    @Input() selectedId: number;
    @Input() noArticlesText: string;
    @Input() basePath: string;

    public firstScrolled = false;
    public expandedArticleIds: number[] = [];
    public mainArticleId: number;
    private paramsSubscription: Subscription;

    public imageClass: object = {};


    constructor(private routing: RoutingService, public language: LanguageService) { }

    ngOnInit() {

        this.noArticlesText = typeof (
            this.noArticlesText) === 'undefined' ? this.defaultNoArticleText : this.noArticlesText;

        this.paramsSubscription = this.routing.params.subscribe(p => {

            const id = Number(p.id);

            if (id) {
                this.mainArticleId = id;

                if (!this.expandedArticleIds.includes(id)) {
                    this.expandedArticleIds.push(id);
                }
            }
        });

    }

    ngOnDestroy() {
        this.paramsSubscription.unsubscribe();
    }

    ngAfterViewChecked() {

        if (this.firstScrolled) {
            return;
        }

        const el = document.getElementById('article-' + this.mainArticleId);
        if (el) {
            const r = el.getBoundingClientRect();
            window.scrollTo(0, r.top - 135);
            this.firstScrolled = true;
        }
    }

    onLoad(event, id) {
        const imageWidth = event.srcElement.clientWidth;
        const imageHeight = event.srcElement.clientHeight;
        const imageClass = (imageWidth <= imageHeight) ? 'img-height' : 'img-width';
        this.imageClass[id] = imageClass;
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }

    isExpanded(article: IArticle) {
        return this.expandedArticleIds.includes(article.id);
    }


    toggleArticle(article: IArticle) {

        if (this.isExpanded(article)) {
            this.expandedArticleIds = this.expandedArticleIds.filter(id => id !== article.id);

        } else if (this.mainArticleId === article.id) {
            this.expandedArticleIds.push(article.id);
        } else {

            this.routing.navigate([this.basePath, article.id]);

        }

    }


}
