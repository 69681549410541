import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { LocationService } from '../location.service';
import { Appliance, DatabaseService, Place } from '../database.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../api.service';
import { RoutingService } from '../routing.service';
import { OpeningHourService } from '../opening-hour.service';


export interface Category {
    // label: string;
    placeTypeCode: string;
    min: number;
    max: number;
    minDistance: number;
    places: Place[];
}

@Component({
    selector: 'near-places',
    templateUrl: './near-places.component.html',
    styleUrls: ['./near-places.component.scss']
})
export class NearPlacesComponent implements OnInit, OnDestroy {

    public categories: Category[] = [
        {
            // label: 'Återvinningscentraler',
            placeTypeCode: 'avc',
            min: 1,
            max: 3,
            minDistance: 10,
            places: null,
        },
        {
            // label: 'Återvinningsstationer',
            placeTypeCode: 'avs',
            min: 1,
            max: 2,
            minDistance: 1,
            places: null,
        },
    ];

    private applianceStatuses: Appliance[] = [];

    private locationSubscription: Subscription;
    private databaseLoadedSubscription: Subscription;

    constructor(public location: LocationService, private database: DatabaseService, private api: ApiService,
        private routing: RoutingService, private zone: NgZone, public openingHourService: OpeningHourService) { }

    ngOnInit() {

        if (this.location.latestLocation) {
            this.database.load()
                .then(() => {
                    this.getPlaces();
                });
        }

        this.databaseLoadedSubscription = this.database.loaded.subscribe(event => {
            this.getPlaces();
        });

        this.locationSubscription = this.location.locationUpdate.subscribe(location => {
            this.getPlaces();
        });

        document.addEventListener('resume', () => {
            this.zone.run(() => {
                this.applianceStatuses = [];
                this.getPlaces();
            });
        }, false)
    }

    ngOnDestroy() {
        this.databaseLoadedSubscription.unsubscribe();
        this.locationSubscription.unsubscribe();
    }

    private getPlaces() {

        if (!(this.location.latestLocation && this.database.isLoaded)) {
            return;
        }

        const placeTypes = this.database.getPlaceTypes();

        Promise.all(this.categories.map(category => {

            const placeType = placeTypes.find(pt => pt.code === category.placeTypeCode);

            return this.database.getNearPlaces(category.max, placeType);
        })).then(categoryPlaces => {


            for (let i = 0; i < categoryPlaces.length; i++) {

                const category = this.categories[i];
                let places = categoryPlaces[i];

                for (let j = 0; j < i; j++) {
                    const previousPlaces = categoryPlaces[j];
                    places = places.filter(place => !previousPlaces.some(p => p.id === place.id));
                }

                places = places.filter((place, index) => {
                    return index < category.min || place.distance <= category.minDistance;
                });

                category.places = places;
            }

            this.updateAppliances();

        });
    }



    getApplianceStatus(appliance: Appliance) {
        return this.applianceStatuses.find(as => as.id == appliance.id);
    }

    updateAppliances() {

        var checkPlaces: Place[] = [];

        this.categories.forEach(category => {
            (category.places || []).forEach(place => {
                (place.appliances || []).forEach(appliance => {

                    var applicanceStatus = this.getApplianceStatus(appliance);

                    if (!applicanceStatus) {
                        this.applianceStatuses.push({ ...appliance, status: undefined });

                        if (checkPlaces.includes(place) == false) {
                            checkPlaces.push(place);
                        }
                    }

                })
            })
        });


        checkPlaces.forEach(place => {
            this.api.execute('places/' + place.id + '/status', 'GET', { id: place.id })
                .then((newApplianceStatuses: Appliance[]) => {

                    newApplianceStatuses.forEach(newApplianceStatus => {

                        var applianceStatus = this.getApplianceStatus(newApplianceStatus);
                        if (applianceStatus) {
                            applianceStatus.status = newApplianceStatus.status;
                        }
                    })

                });

        });

    }

    applianceStatusText(appliance: Appliance) {
        var applianceStatus = this.getApplianceStatus(appliance);
        var status = applianceStatus && applianceStatus.status;

        switch (status) {
            case 0: return this.database.getText('appliance-status-closed');
            case 1: return this.database.getText('appliance-status-open');
            default: return this.database.getText('appliance-status-loading');
        }
    }

    applianceStatusClass(appliance: Appliance) {
        var applianceStatus = this.getApplianceStatus(appliance);
        var status = applianceStatus && applianceStatus.status;
        return status ? 'info active' : 'info inactive';
    }

    placeAppliances(place: Place) {

        if (place.appliances) {
            return place.appliances;
        }

        return [];
    }

    requestLocation() {
        this.location.requestLocation()
            .then(() => {
                this.getPlaces();
            });
    }


    moreInfoLinkClicked(place: Place) {
        this.routing.navigate(['/search', place.id + ': ' + place.name]);
    }

}
