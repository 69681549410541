<div class="articles-wrapper">
    <ng-container *ngFor="let article of articles; let i = index">
        <div class="article" [id]="'article-'+article.id">
            <div class="title" *ngIf="article.header" touchEvents (touchClick)="toggleArticle(article)" #{{article.id}}>
                <span [ngClass]="['fa',(isExpanded( article )?'fa-minus':'fa-chevron-down')]" class="toggle-icon"
                    [class]="language.rtlEnabled ? 'rtl' : ''"></span>
                <h3>
                    <span>{{ article.header }}</span>
                </h3>
            </div>
            <ng-container *ngIf="isExpanded( article )">
                <!-- <hr /> -->
                <div class="image" *ngIf="article.image">
                    <img src="{{ article.image }}" (load)="onLoad($event, article.id)"
                        class="{{imageClass[article.id]}}" />
                </div>
                <div class="content" [class]="language.rtlEnabled ? 'rtl' : ''">
                    <div [innerHTML]="article.content"></div>
                </div>
                <div class="link" *ngIf="article.linkHref">
                    <a [href]="article.linkHref" target="_blank" class="btn-round" [title]="article.linkText"><i
                            class="fa fa-external-link"></i>&nbsp;{{ article.linkText }}</a>
                </div>
            </ng-container>
        </div>
        <div class="spacer">&nbsp;</div>
    </ng-container>
    <ng-container *ngIf="articles.length === 0">
        <div class="article">
            <div class="content">
                {{ noArticlesText }}
            </div>
        </div>
    </ng-container>
</div>