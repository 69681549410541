import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SearchComponent } from './search/search.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { HomeComponent } from './home/home.component';
import { SearchResultWasteComponent } from './search-result-waste/search-result-waste.component';
import { SearchResultPlaceComponent } from './search-result-place/search-result-place.component';
import { NearPlacesComponent } from './near-places/near-places.component';
import { PlaceInfoComponent } from './place-info/place-info.component';
import { PlaceHoursComponent } from './place-hours/place-hours.component';
import { DepositTypeInfoComponent } from './deposit-type-info/deposit-type-info.component';
import { PlaceSummaryComponent } from './place-summary/place-summary.component';
/*
import { BubbleComponent } from './bubble/bubble.component';
import { MenuBubbleComponent } from './menu-bubble/menu-bubble.component';
*/
import { ExpanderComponent } from './expander/expander.component';
import { MapComponent } from './map/map.component';
import { InfoComponent } from './info/info.component';
import { SearchResultEmptyComponent } from './search-result-empty/search-result-empty.component';
import { SearchResultTipComponent } from './search-result-tip/search-result-tip.component';
import { LoadingComponent } from './loading/loading.component';
import { TipComponent } from './tip/tip.component';
import { ArticlesComponent } from './articles/articles.component';
import { TouchEventsDirective } from './touch-events.directive';
import { IntroComponent } from './intro/intro.component';
import { TextDirective } from './text.directive';
import { WasteInfoComponent } from './waste-info/waste-info.component';
import { AppliancesInfoComponent } from './appliances-info/appliances-info.component';
import { FavoriteButtonComponent } from './favorite-button/favorite-button.component';
import { LanguagesComponent } from './languages/languages.component';
import { LanguageFirstTimeComponent } from './language-first-time/language-first-time.component';

@NgModule({
    declarations: [
        AppComponent,
        SearchComponent,
        SearchResultComponent,
        HomeComponent,
        SearchResultWasteComponent,
        SearchResultPlaceComponent,
        NearPlacesComponent,
        PlaceInfoComponent,
        PlaceHoursComponent,
        DepositTypeInfoComponent,
        PlaceSummaryComponent,
        /*
        BubbleComponent,
        MenuBubbleComponent,
        */
        ExpanderComponent,
        MapComponent,
        InfoComponent,
        SearchResultEmptyComponent,
        SearchResultTipComponent,
        LoadingComponent,
        TipComponent,
        ArticlesComponent,
        TouchEventsDirective,
        IntroComponent,
        TextDirective,
        WasteInfoComponent,
        AppliancesInfoComponent,
        FavoriteButtonComponent,
        LanguagesComponent,
        LanguageFirstTimeComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // registrationStrategy: 'registerImmediately',
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
