<!-- <loading [loading]="isLoading" (cancel)="cancelRead()"></loading> -->
<ng-container *ngFor="let resultGroup of resultGroups">

    <search-result-waste *ngIf="resultGroup.type==SearchResultType.waste" [item]="resultGroup"
        [expanded]="resultGroups.length == 1"></search-result-waste>
    <search-result-place *ngIf="resultGroup.type==SearchResultType.place" [item]="resultGroup.results[0].item"
        [expanded]="resultGroups.length == 1"></search-result-place>

</ng-container>
<search-result-empty *ngIf="!isLoading && searchTerm && resultGroups?.length==0" [term]="searchTerm" [expanded]="true">
</search-result-empty>
<ng-container *ngIf="!searchTerm">
    <intro *ngIf="!database.introduced; else introduced"></intro>
    <ng-template #introduced>
        <app-language-first-time></app-language-first-time>
        <near-places></near-places>
        <tip [random]="1"></tip>
    </ng-template>
</ng-container>