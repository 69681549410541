import { Component, Output, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocationService } from '../location.service';
import { LoadingService } from '../loading.service';
import {
    DatabaseService, SearchResult, SearchResultGroup,
    SearchResultType
} from '../database.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit, OnDestroy {

    public searchTerm: string;

    public searching = false;
    public resultGroups: SearchResultGroup[];

    private routeSubscription: Subscription;
    private locationSubscription: Subscription;
    private databaseLoadedSubscription: Subscription;
    private loadingSubscription: Subscription;

    public SearchResultType = SearchResultType; // making this enum available in the template

    // private _isLoading = false;
    public isLoading = false;

    constructor(
        private route: ActivatedRoute,
        public database: DatabaseService,
        private location: LocationService,
        private loading: LoadingService
    ) {
        // console.log('constructor in SearchResultComponent ', this.loading);
        // this.isLoading = this.loading.isLoading;
    }

    ngOnInit() {
        this.isLoading = this.loading.isLoading;
        // this.loadingSubscription = this.loading.loadingUpdate.subscribe( (loading) => {
        //     this.isLoading = loading;
        // });
        this.routeSubscription = this.route.params.subscribe(params => {
            const newTerm = params.term || '';

            // console.log('in routeSubscription() params', params);
            if (newTerm !== this.searchTerm) {

                this.search(newTerm);
            }
        });

        this.locationSubscription = this.location.locationUpdate.subscribe(location => {
            // console.log('in locationSubscription() location', location);
            this.search();
        });

        this.databaseLoadedSubscription = this.database.loaded.subscribe(event => {
            console.log('Database loaded, updating search');
            // console.log('in databaseLoadedSubscription() event', event);
            this.search();
        });
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.locationSubscription.unsubscribe();
        this.databaseLoadedSubscription.unsubscribe();
        // this.loadingSubscription.unsubscribe();
    }
    // public set isLoading(loading) {
    //     this.loading.isLoading = loading;
    //     this._isLoading = loading;
    // }
    // public get isLoading() {
    //     return this._isLoading;
    // }
    // public cancelRead() {
    //     this.isLoading = false;
    // }

    search(term?: string) {

        // console.log('in search() term', term);
        this.loading.isLoading = true;
        this.isLoading = true;
        if (typeof (term) === 'string') {
            this.searchTerm = term;
        }

        this.searching = true;
        // this.resultGroups = [];

        let allResults: SearchResult[] = [];

        this.database.searchWastes(this.searchTerm)
            .then(results => {
                allResults = allResults.concat(results);
            })
            .then(() => {
                return this.database.searchPlaces(this.searchTerm);
            })
            .then(results => {
                allResults = allResults.concat(results);
            })
            .then(() => {


                if (allResults.length > 0) {

                    allResults.sort((a, b) => a.score - b.score);
                    if (allResults[0].score === 0) {
                        allResults = allResults.filter(r => r.score === 0);
                    } else {
                        allResults = allResults.slice(0, 30);
                    }
                }

                const resultGroups = this.database.groupResults(allResults);

                this.resultGroups = resultGroups;
            })
            .finally(() => {
                this.searching = false;
                this.loading.isLoading = false;
                this.isLoading = false;
            });

    }

}
