import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LoadingService } from '../loading.service';
import { DatabaseService } from '../database.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService } from '../language.service';

@Component({
    selector: 'tip',
    templateUrl: './tip.component.html',
    styleUrls: ['./tip.component.scss']
})
export class TipComponent implements OnInit, OnDestroy {
    @Input() random: number;

    private databaseLoadedSubscription?: Subscription;

    private routeSubscription: Subscription;
    public tips = [];
    public tipId: number;

    public get noArticlesText() {
        return this.database.getText('tips-empty');
    }

    constructor(
        private loading: LoadingService,
        private route: ActivatedRoute,
        private router: Router,
        private database: DatabaseService,
        public language: LanguageService) { }

    ngOnInit() {
        // set this flag to true and it will be set to false in articles.components
        /*
        this.loading.isLoading = true;

        this.routeSubscription = this.route.params.subscribe(params => {
            const newTipId = params.id || '';

            if (newTipId !== this.tipId) {
                this.tipId = newTipId;
            }
        });
        */
        this.loadTips()
            // .then( () => this.loading.isLoading = false)
            ;


        this.databaseLoadedSubscription = this.database.loaded.subscribe(event => {
            console.log('database updated, reloading tips');
            this.loadTips();
        });

    }

    ngOnDestroy() {
        this.databaseLoadedSubscription?.unsubscribe();
    }

    loadTips() {
        return this.database.load()
            .then(() => {
                this.tips = this.database.getTips();
            })
            .then(() => {
                if (typeof (this.random) !== 'undefined') {
                    // get random number of tips
                    if (this.tips.length > 0) {
                        this.tips = [this.tips[Math.floor(Math.random() * this.tips.length)]];
                    }
                }
            })
            ;
    }

    /*
ngOnDestroy() {
    this.routeSubscription.unsubscribe();
}
*/

    goToArticle(tipId) {
        this.router.navigate(['./tip/' + tipId]);
    }
}
