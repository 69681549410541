import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchResultComponent } from './search-result/search-result.component';
import { HomeComponent } from './home/home.component';
import { MapComponent } from './map/map.component';
import { InfoComponent } from './info/info.component';
import { environment } from '../environments/environment';
import { TipComponent } from './tip/tip.component';
import { LanguagesComponent } from './languages/languages.component';

const routes: Routes = [
    {
        path: '',
        component: SearchResultComponent,
    },
    {
        path: 'search',
        redirectTo: 'search/',
    },
    {
        path: 'search/:term',
        component: SearchResultComponent,
    },
    {
        path: 'map',
        component: MapComponent,
    },
    {
        path: 'tip',
        component: TipComponent,
    },
    {
        path: 'tip/:id',
        component: TipComponent,
    },
    {
        path: 'info',
        component: InfoComponent,
    },
    {
        path: 'info/:id',
        component: InfoComponent,
    },
    {
        path: 'languages',
        component: LanguagesComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: environment.hashRouting })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
