import { Component, OnInit, OnDestroy, HostListener, Output, EventEmitter, ViewChild, ElementRef, NgZone, AfterViewInit } from '@angular/core';

import { RoutingService } from '../routing.service';
import { DatabaseService } from '../database.service';
import { ContextService } from '../context.service';


@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {

    searchTerm = '';

    @Output() change = new EventEmitter<string>();
    @ViewChild('input') input: ElementRef;

    private changeDelayTimeoutId;
    private paramsChangedSubscription;
    private blurOnScroll = true;
    private boxClickFocused = false;

    private keyUpListener: any;
    private keyDownListener: any;
    private changeListener: any;

    private isInputFocused = false;

    private onTimeout;

    private emitDelay: number;

    private onScrollListener: any;

    constructor(
        private routing: RoutingService,
        private database: DatabaseService,
        private context: ContextService,
        private zone: NgZone,
    ) { }

    ngOnInit() {

        this.emitDelay = this.context.isMobile ? 500 : 100;

        this.zone.runOutsideAngular(() => {

            this.onScrollListener = event => this.onScroll(event);
            window.addEventListener('scroll', this.onScrollListener);
        });

    }

    ngAfterViewInit() {

        this.paramsChangedSubscription = this.routing.params.subscribe(params => {

            if ('term' in params && params.term !== this.searchTerm) {
                this.searchTerm = params.term;
                this.setInputValue();
            }

        });

        this.zone.runOutsideAngular(() => {
            this.input.nativeElement.addEventListener('keydown', this.keyDownListener = event => this.keyDown(event));
            this.input.nativeElement.addEventListener('keyup', this.keyUpListener = event => this.keyUp(event));
        });

    }


    ngOnDestroy() {
        this.paramsChangedSubscription.unsubscribe();

        this.input.nativeElement.removeEventListener('keydown', this.keyDownListener);
        this.input.nativeElement.removeEventListener('keyup', this.keyUpListener);
        //this.input.nativeElement.removeEventListener('change', this.changeListener);

        if (this.onScrollListener) {
            window.removeEventListener('scroll', this.onScrollListener);
            delete this.onScrollListener;
        }
    }

    // NOTE: this is called outside angular zone, but this is fine as long as it's just calls to html elements
    onScroll(event) {
        if (this.blurOnScroll) {
            this.input.nativeElement.blur();
        }
    }

    private soon(cb, ms, runOutside = false) {

        if (runOutside) {
            this.zone.runOutsideAngular(() => setTimeout(cb, ms));
        } else {
            setTimeout(cb, ms);
        }
    }

    blurred() {
        if (this.boxClickFocus) {
            this.boxClickFocused = false;
        }
        this.emitChange();
        this.isInputFocused = false;
    }

    focused() {
        this.isInputFocused = true;
    }

    focus() {

        if (window.scrollY !== 0) {

            this.blurOnScroll = false;

            window.scrollTo(0, 0);
            this.soon(() => {
                this.blurOnScroll = true;
            }, 1000, true);

        }
        this.input.nativeElement.select();

    }

    get disabled() {
        return this.boxClickFocus && !this.boxClickFocused;
    }

    get boxClickFocus() {
        return this.context.isIOS || this.context.isAndroid;
    }

    boxClick() {
        if (this.boxClickFocus && !this.boxClickFocused) {
            this.boxClickFocused = true;
            this.soon(() => this.focus(), 100);
        }
    }

    keyDown(event) {
        if (event.key === 'Enter') {
            this.input.nativeElement.blur();
            this.emitChange();
        }
    }

    keyUp(event?) {
        this.changed();
    }


    changed(event?) {

        if (!this.onTimeout) {
            this.onTimeout = () => this.emitChange();
        }

        clearTimeout(this.changeDelayTimeoutId);
        this.changeDelayTimeoutId = setTimeout(this.onTimeout, this.emitDelay);
    }

    clear() {
        this.searchTerm = '';
        this.setInputValue(true);
        this.focus();
    }

    private emitChange() {

        this.zone.run(() => {

            this.searchTerm = this.input.nativeElement.value;

            const lcTerm = this.searchTerm.toLowerCase();

            this.change.emit(lcTerm);
        });
    }

    private setInputValue(force = false) {
        if (this.isInputFocused === false || force) {
            this.input.nativeElement.value = this.searchTerm;
        }
    }

    get placeholder(): string {
        return this.database.getText('search-placeholder', '');
    }

}
